export const schema = {
    Person: {
        label: "Person",
        value: {
            name: ""
        },
        rel: [
            {
                label: "WORKED_AT",
                target: {
                    label: "Company",
                },
            },
            {
                label: "STUDIED_AT",
                target: {
                    label: "College",
                }
            }
        ]
    },
    Company: {
        label: "Company",
        value: {
            name: ""
        },
        rel: [
            {
                label: "WORKED_AT",
                target: {
                    label: "Person",
                },
            }
        ]
    },
    College: {
        label: "College",
        value: {
            name: ""
        },
        rel: [
            {
                label: "STUDIED_AT",
                target: {
                    label: "Person",
                }
            }
        ]
    }
}