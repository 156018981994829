import {
    SUBSCRIBE_USER_REQUEST,
    SUBSCRIBE_USER_SUCCESS,
    SUBSCRIBE_USER_FAILED,
    SCHEDULE_APPOINTMENT_REQUEST,
    SCHEDULE_APPOINTMENT_SUCCESS,
    SCHEDULE_APPOINTMENT_FAILED,
    TOGGLE_SIDEBAR,
    SET_MODAL_STATUS, FECTH_FROM_DIGILOCKER_REQUEST, FECTH_FROM_DIGILOCKER_SUCCESS, DOCUMENTS_NOT_FOUND,  FECTH_FROM_DIGILOCKER_FAILED,
    GET_SCHEME_LIST_REQUEST,
    GET_SCHEME_LIST_SUCCESS,
    GET_SCHEME_LIST_FAILED,
    SET_SELECTED_FUNDS,
} from "../Constants/actionTypes.constants";
import { getApiErrorMessage } from '../utils/index';

export const initialStore = {
    userSubscribed: false,
    appointmentScheduled: false,
    loading: false,
    error: false,
    errorMessage: "",
    modalActive: false,
    digilockerData: {},
    expanded: false,
    schemeList: {},
    selectedFunds: [],
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case SUBSCRIBE_USER_REQUEST:
            return {
                ...state,
                userSubscribed: false,
                loading: true,
                error: false,
                errorMessage: "",
            };
        case SUBSCRIBE_USER_SUCCESS:
            return {
                ...state,
                userSubscribed: true,
                loading: false,
                error: false,
                errorMessage: "",
            };
        case SUBSCRIBE_USER_FAILED:
            return {
                ...state,
                userSubscribed: false,
                loading: false,
                error: true,
                errorMessage: getApiErrorMessage(action.error),
            };
        case SCHEDULE_APPOINTMENT_REQUEST:
            return {
                ...state,
                loading: true,
                appointmentScheduled: false,
                error: false,
                errorMessage: "",
            };
        case SCHEDULE_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                appointmentScheduled: true,
                error: false,
                errorMessage: "",
            };
        case SCHEDULE_APPOINTMENT_FAILED:
            return {
                ...state,
                loading: false,
                appointmentScheduled: false,
                error: true,
                errorMessage: getApiErrorMessage(action.error),
            };
        case FECTH_FROM_DIGILOCKER_REQUEST:
            return {
                ...state,
                loading: true,
                digilockerData: {},
                error: false,
                errorMessage: "",
            };
        case FECTH_FROM_DIGILOCKER_SUCCESS:
            return {
                ...state,
                loading: false,
                digilockerData: action.data,
                error: false,
                errorMessage: "",
            };
        case DOCUMENTS_NOT_FOUND:
            return {
                ...state,
                loading: false,
                digilockerData: action.data,
                error: false,
                errorMessage: "",
            };
        case FECTH_FROM_DIGILOCKER_FAILED:
            return {
                ...state,
                loading: false,
                digilockerData: {},
                error: true,
                errorMessage: getApiErrorMessage(action.error),
            };
        case SET_MODAL_STATUS:
            return {
                ...state,
                modalActive: action.data,
            };
        case TOGGLE_SIDEBAR:
            return {
                ...state,
                expanded: action.data,
            };
        case GET_SCHEME_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                schemeList: {},
                error: false,
            };
        case GET_SCHEME_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                schemeList: action.data,
                error: false,
            };
        case GET_SCHEME_LIST_FAILED:
            return {
                ...state,
                loading: false,
                schemeList: {},
                error: true,
            };
        case SET_SELECTED_FUNDS:
            return {
                ...state,
                selectedFunds: action.data,
            };
        default:
            return state
    }
}