import {
    CHART_TRACKRECORD_REQUEST,
    CHART_TRACKRECORD_SUCCESS,
    CHART_TRACKRECORD_FAILED,
} from "../Constants/actionTypes.constants";

export const initialStore = {
    graphData: [],
};



export default (state = initialStore, action) => {
    switch (action.type) {
        case CHART_TRACKRECORD_REQUEST:
            return {
                ...state,
                loading: true,
                graphData: [],
                error: false,
            };
        case CHART_TRACKRECORD_SUCCESS:
            return {
                ...state,
                loading: false,
                graphData: action.data,
                error: false,
            };
        case CHART_TRACKRECORD_FAILED:
            return {
                ...state,
                loading: false,
                graphData: [],
                error: true,
            };
        default:
            return state
    }
}

