import {
    GET_PORTFOLIO_VIEW_REQUEST,
    GET_PORTFOLIO_VIEW_SUCCESS,
    GET_PORTFOLIO_VIEW_FAILED,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_REQUEST,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_SUCCESS,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_FAILED,
    FETCH_SECTOR_DISTRIBUTION_REQUEST,
    FETCH_SECTOR_DISTRIBUTION_SUCCESS,
    FETCH_SECTOR_DISTRIBUTION_FAILED,
    FETCH_PORTFOLIO_PDF_REQUEST,
    FETCH_PORTFOLIO_PDF_SUCCESS,
    FETCH_PORTFOLIO_PDF_FAILED,
    SET_PORTFOLIO_SIP_REQUEST,
    SET_PORTFOLIO_SIP_SUCCESS,
    SET_PORTFOLIO_SIP_FAILED,
    GET_PORTFOLIO_FUND_LIST_REQUEST,
    GET_PORTFOLIO_FUND_LIST_SUCCESS,
    GET_PORTFOLIO_FUND_LIST_FAILED,
    SET_ARBITRARY_REDEMPTION_ORDER_DETAILS,
    SET_ARBITRARY_PURCHASE_ORDER_DETAILS,
    SET_PURCHASE_ORDER,
    REBALANCE_ALLOCATION_REQUEST,
    REBALANCE_ALLOCATION_SUCCESS,
    REBALANCE_ALLOCATION_FAILED,
    FETCH_RECOMMENDATIONS_REQUEST,
    FETCH_RECOMMENDATIONS_SUCCESS,
    FETCH_RECOMMENDATIONS_FAILED,
    SET_REDEMPTION_WARNING_VIEW,
    ECAN_BANK_DETAILS_REQUEST,
    ECAN_BANK_DETAILS_SUCCESS,
    ECAN_BANK_DETAILS_FAILED,
    SET_REDEMPTION_STATUS,
    SET_BUY_GROUP_ORDER_DETAILS,
    SET_SELL_GROUP_ORDER_NO,
    REDEMPTION_ORDER_SUCCESS,
    REDEMPTION_ORDER_FAILED,
    RESET_SIP_ERROR,
    OPEN_FUND_PURCHASE,
    OPEN_SIP_PURCHASE,
} from '../../Constants/mfActionTypes.constants';
import { get } from 'lodash';
import { GiConsoleController } from 'react-icons/gi';
export const initialStore = {
  error: false,
  portfolioDetails: {},
  sectorDistributionDetails: {},
  portfolioPdf: {},
  sipDetails: {},
  sipLoader:false,
  portfolioRedeemFundList: {},
  portfolioFundList:{},
  purchaseOrderDetails:{},
  setPurchaseOrder: false,
  rebalanceAllocationDetails: {},
  updatedOrderDetails: [],
  redemptionWarningView: false,
  ecanBankDetails: {},
  redemptionStatus:true,
  orderBasket: {},
  setBuyGroupOrderDetails:{},
  setSellGroupOrderNo: "",
  redemOrderSuccessFlag: false,
  redemOrderFailedFlag: false,
  redeemOrderDetails:{},
  sipError:{},
  showFundPurchase: false,
  sipPurchase: false,
};

export default (state = initialStore, action) => {
  switch (action.type) {
        case GET_PORTFOLIO_VIEW_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_PORTFOLIO_VIEW_SUCCESS:
            return {
                ...state,
                portfolioDetails: action.data,
                error: false,
            };
        case GET_PORTFOLIO_VIEW_FAILED:
            return {
                ...state,
                portfolioDetails: {},
                error: true,
            };
        case GET_RECENT_DETAILS_VERIFICATION_HOOK_REQUEST:
            return {
                ...state,
            };
        case GET_RECENT_DETAILS_VERIFICATION_HOOK_SUCCESS:
            return {
                ...state,
                portfolioDetails: { ...state.portfolioDetails, [action.portfolio_details]: action.data.userData }
            };
        case GET_RECENT_DETAILS_VERIFICATION_HOOK_FAILED:
            return {
                ...state,
            };
        case FETCH_SECTOR_DISTRIBUTION_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_SECTOR_DISTRIBUTION_SUCCESS:
            return {
                ...state,
                sectorDistributionDetails: action.data,
                error: false,
            };
        case FETCH_SECTOR_DISTRIBUTION_FAILED:
            return {
                ...state,
                error: true,
            };
        case FETCH_PORTFOLIO_PDF_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_PORTFOLIO_PDF_SUCCESS:
            return {
                ...state,
                portfolioPdf: action.data,
                error: false,
            };
        case FETCH_PORTFOLIO_PDF_FAILED:
            return {
                ...state,
                error: true,
            };
        case SET_PORTFOLIO_SIP_REQUEST:
            return {
                ...state,
                error: false,
                sipLoader: true,
            };
        case SET_PORTFOLIO_SIP_SUCCESS:
            return {
                ...state,
                sipDetails: action.data,
                error: false,
                sipLoader: false,
            };
        case SET_PORTFOLIO_SIP_FAILED:
            return {
                ...state,
                sipDetails: {},
                error: true,
                sipLoader: false,
                sipError:action.error.response ==='undefined'? action.error : action.error.response.data ,
            };
        default:
          return state;

      case GET_PORTFOLIO_FUND_LIST_REQUEST:
          return {
              ...state,
              error: false,
          };
      case GET_PORTFOLIO_FUND_LIST_SUCCESS:
          return {
              ...state,
              portfolioFundList: get(action.data, 'purchase', {}),
              portfolioRedeemFundList: get(action.data, 'redeem', {}),
              error: false,
          };
      case GET_PORTFOLIO_FUND_LIST_FAILED:
          return {
              ...state,
              error: true,
          };
      case SET_ARBITRARY_REDEMPTION_ORDER_DETAILS:
          return {
              ...state,
              redeemOrderDetails: { ...state.redeemOrderDetails, [action.id]: action.data },
              updatedOrderDetails: action.data.fundList,
          };
      case SET_ARBITRARY_PURCHASE_ORDER_DETAILS:
          return {
              ...state,
              purchaseOrderDetails: action.data,
              updatedOrderDetails: action.data.fundList,
          };
      case SET_PURCHASE_ORDER:
          return {
              ...state,
              setPurchaseOrder: action.flag,
          };

      case REBALANCE_ALLOCATION_REQUEST:
          return {
              ...state,
              error: false,
          };
      case REBALANCE_ALLOCATION_SUCCESS:
          return {
              ...state,
              rebalanceAllocationDetails: action.data,
              error: false,
          };
      case REBALANCE_ALLOCATION_FAILED:
          return {
              ...state,
              error: true,
          };

      case FETCH_RECOMMENDATIONS_REQUEST:
          return {
              ...state,
              error: false,
          };
      case FETCH_RECOMMENDATIONS_SUCCESS:
          return {
              ...state,
              orderBasket: action.data,
              error: false,
          };
      case FETCH_RECOMMENDATIONS_FAILED:
          return {
              ...state,
              error: true,
          };
      case SET_REDEMPTION_WARNING_VIEW:
          return {
              ...state,
              redemptionWarningView: action.flag,
          };

      case ECAN_BANK_DETAILS_REQUEST:
          return {
              ...state,
              error: false,
          };
      case ECAN_BANK_DETAILS_SUCCESS:
          return {
              ...state,
              ecanBankDetails: action.data,
              error: false,
          };
      case ECAN_BANK_DETAILS_FAILED:
          return {
              ...state,
              error: true,
          };

      case SET_REDEMPTION_STATUS:
          return {
              ...state,
              redemptionStatus: action.status,
          };

      case SET_BUY_GROUP_ORDER_DETAILS:
          return {
              ...state,
              setBuyGroupOrderDetails: action.buyDetails,
          };
      case SET_SELL_GROUP_ORDER_NO:
          return {
              ...state,
              setSellGroupOrderNo: action.orderNo,
          };
      case REDEMPTION_ORDER_SUCCESS:
          return {
              ...state,
              redemOrderSuccessFlag: action.flag
          }
      case REDEMPTION_ORDER_FAILED:
          return {
              ...state,
              redemOrderFailedFlag: action.flag
          }
      case RESET_SIP_ERROR:
          return {
              ...state,
              sipError: action.data
          }
    case OPEN_FUND_PURCHASE: {
        return {
        ...state,
        showFundPurchase: action.flag
        }
    }

    case OPEN_SIP_PURCHASE:{
        return{
           ...state,
           sipPurchase: action.flag 
        }
    }

  }

};
