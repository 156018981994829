import {
    GET_KYC_FORM_DATA_REQUEST,
    GET_KYC_FORM_DATA_SUCCESS,
    GET_KYC_FORM_DATA_FAILED,
    POST_KYC_FORM_DATA_REQUEST, 
    POST_KYC_FORM_DATA_SUCCESS, 
    POST_KYC_FORM_DATA_FAILED,
    SET_KYC_PDF_URL,
    SUBMIT_KYC_FORM_DATA_REQUEST, 
    SUBMIT_KYC_FORM_DATA_SUCCESS, 
    SUBMIT_KYC_FORM_DATA_FAILED,
} from "../Constants/actionTypes.constants";


export const initialStore = {
    loading:false,
    error:false,
    kycformData:{
        application_kyc_data: {
          aadhaar_number: '', 
          cor_add1: '', 
          cor_add2: '', 
          cor_add3: '', 
          cor_add_city: '', 
          cor_add_country: '', 
          cor_add_pincode: "", 
          cor_add_proof: "", 
          cor_add_state: "", 
          date_of_declaration: "", 
          dob: "", 
          document_type: "", 
          email: "", 
          father_name: "", 
          gender: "", 
          ipv_date: "", 
          marital_status: "", 
          mob_no: "", 
          name: "", 
          nationality: "", 
          other_nationality: "", 
          pan_no: "", 
          per_add1: "", 
          per_add2: "", 
          per_add3: "", 
          per_add_city: "", 
          per_add_country: "", 
          per_add_flag: "", 
          per_add_pincode: "", 
          per_add_proof: "", 
          per_add_state: "", 
          place_of_declaration: "", 
          pos_code: "", 
          residential_status: "", 
          spouse_name: "", 
          validity_of_cor_add_proof: "", 
          validity_of_per_add_proof: ""
        }, 
        b64_photo: "",
        signature64:"",
        stamp64:""
      },
      kycPostSubmitStatus:"",
      kycgeneratestatus:"",
      kycPdfUrl: '',
      kycSubmissionPdf: '',   
      kycSubmitStatus: '',   
      kycSubmitErrorMsg: {},   
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case POST_KYC_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_KYC_FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                kycSubmissionPdf: action.data.submissionForm,
                kycPostSubmitStatus:"SUCCESS"
            };
        case POST_KYC_FORM_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error:true,
                kycPostSubmitStatus:"FAILED"
            };
        case SUBMIT_KYC_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case SUBMIT_KYC_FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                kycSubmitStatus: "SUCCESS",
            };
        case SUBMIT_KYC_FORM_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error:true,
                kycSubmitErrorMsg: action.data,
                kycSubmitStatus:"FAILED"
            };
        case GET_KYC_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GET_KYC_FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                kycformData: action.data,
                kycgeneratestatus:"SUCCESS"
            };
        case GET_KYC_FORM_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error:true,
                kycgeneratestatus:"FAILED"
            };
        case SET_KYC_PDF_URL:
            return {
                ...state,
                kycPdfUrl:action.data
            };
        default:
            return state
    }
}

