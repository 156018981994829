import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { get } from "lodash";
import {
  SEND_PHONE_VERIFICATION_REQUEST,
  SEND_PHONE_VERIFICATION_SUCCESS,
  SEND_PHONE_VERIFICATION_FAILED,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_FAILED,
  GET_RECENT_DETAILS_VERIFICATION_HOOK_REQUEST,
  GET_RECENT_DETAILS_VERIFICATION_HOOK_SUCCESS,
  GET_RECENT_DETAILS_VERIFICATION_HOOK_FAILED,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILED,



  SET_ACTIVE_STEP_NUMBER,
  SET_UPDATE_KYC,
  SET_CHEQUE_IMG_SRC,
  RESET_CHEQUE,
  RESELECT_CHEQUE,
  SET_BANK_STATEMENT_IMG_SRC,
  SET_CHEQUE_UPLOAD_STATUS,




  OPEN_PROFILE,

  OPEN_CKYC,
  OPEN_PORTFOLIO,
  OPEN_OAUTH,
  CREATE_IB_ACCOUNT_SUCCESS,
  CREATE_IB_ACCOUNT_FAILED,
  GET_CHEQUE_OCR_DATA_REQUEST,
  GET_CHEQUE_OCR_DATA_SUCCESS,
  GET_CHEQUE_OCR_DATA_FAILED,
  UPDATE_CHEQUE_DATA,
  VERIFY_ACCOUNT_REQUEST,
  VERIFY_ACCOUNT_FAILED,
  VERIFY_ACCOUNT_SUCCESS,
  PARSE_BANK_STATEMENT_REQUEST,
  PARSE_BANK_STATEMENT_SUCCESS,
  PARSE_BANK_STATEMENT_VALIDATION,
  PARSE_BANK_STATEMENT_FAILED,
  SEND_EMAIL_VERIFICATION_REQUEST,
  SEND_EMAIL_VERIFICATION_SUCCESS,
  SEND_EMAIL_VERIFICATION_FAILED,

  FETCH_CVLKRA_DOCUMENT_REQUEST,
  FETCH_CVLKRA_DOCUMENT_SUCCESS,
  FETCH_CVLKRA_DOCUMENT_FAILED,
  SET_ESIGN_DOC_TYPE,
  GET_IB_ACCOUNT_STATUS_FLAG_REQUEST,
  GET_IB_ACCOUNT_STATUS_FLAG_SUCCESS,
  GET_IB_ACCOUNT_STATUS_FLAG_FAILED,
  SET_COMPLETED_STEP_NUMBER,
  TOKEN_EXPIRY,
  FETCH_KYC_REQUEST,
  FETCH_KYC_SUCCESS,
  FETCH_KYC_FAILED,

  FETCH_ESIGN_DOCUMENT_REQUEST,
  FETCH_ESIGN_DOCUMENT_SUCCESS,
  FETCH_ESIGN_DOCUMENT_FAILED,
  VERIFY_ECAN_EXISTS_REQUEST,
  VERIFY_ECAN_EXISTS_SUCCESS,
  ECAN_NOT_EXISTS,
  VERIFY_ECAN_EXISTS_FAILED,
  VERIFY_KYC_EXISTS_REQUEST,
  VERIFY_KYC_EXISTS_SUCCESS,
  KYC_NOT_EXISTS,
  VERIFY_KYC_EXISTS_FAILED,
  GET_ECAN_MESSAGES,
  SET_SKIP_PORTFOLIO_VIEW,
  GET_OMNIBUS_STATUS_REQUEST,
  GET_OMNIBUS_STATUS_SUCCESS,
  GET_OMNIBUS_STATUS_FAILED,
  SET_SELECTED_FUNDS,
  SUCCESSFULL_SUBMISSION_CKYC,
  GO_TO_DASHBOARD_SUCCESS,
  OPEN_ERROR_MODAL,
  CLOSE_ERROR_MODAL,
  RESET_EMAIL_ERROR,

  OPEN_LEFT_MODAL,
  CLOSE_LEFT_MODAL,
  SET_MODAL_HEADER,
  OPEN_USERPROFILE,
  SCHEDULE_APPOINTMENT_REQUEST,
  SCHEDULE_APPOINTMENT_SUCCESS,
  SCHEDULE_APPOINTMENT_FAILED,


} from "../../Constants/mfActionTypes.constants";
import { getFromLocalStorage } from "../../utils";
import { getApiErrorMessage } from '../../utils/index';
export const initialStore = {
  loading: false,
  error: false,
  errorMessage: "",
  errorContent: "",
  appointmentScheduled: false,
  phoneOtp: "",
  kycPhone: getFromLocalStorage("phoneNumber")
    ? getFromLocalStorage("phoneNumber")
    : "",
  userDetails: {},
  tokenExpiry: false,

  mfUserDetails: {},
  stateCityDetails: {},


  cvlkraGeneratedPdf: {},

  activeStep: 1,
  completedStepNo: 1,





  ibFlag: null,
  esignDocument: {},
  setUpdateKYC: false,
  chequeSrcUrl: "",
  chequeUploaded: false,
  chequeSelected: false,
  bankDetailsSource: "",
  userChequeData: {},
  bankStatementSrcUrl: "",
  esignDocType: '',

  cvlkraEsignedPdf: {},
  kycExists:null,
  ecanExists: null,
  ecanData:[],
  portfolioAvailable: null,
  oauthPending: null,
  purchasePending: false,
  consentPending: null,
  skipPortfolioView: false,
  omninusStatus: {},
  ecanDetails: {},
  showProfile:false,
  showFaq:false,
  selectedFunds: [],
  showCKYC:false,
  showPortfolio:false,
  showOauth: false,
  ckyc_submitted: false,
  goToDashboard:false,
  errorIsOpen:false,
  leftModal:false,
  emailResponse:'',
  emailSuccessResponse:'',
  modalHeader:'',
  showUserProfile:false,
  getUserDetailLoading:false,
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case SEND_PHONE_VERIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SEND_PHONE_VERIFICATION_SUCCESS:
      return {
        ...state,
        kycPhone: action.phone,
        phoneOtp: "",
        loading: false,
        error: false,
      };
    case SEND_PHONE_VERIFICATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        phoneOtp: "",
        userDetails: {},
        error: false,
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        phoneOtp: action.phoneOtp,
        userDetails: action.data.user_details,
        tokenExpiry: false,
        error: false,
      };
    case VERIFY_OTP_FAILED:
      return {
        ...state,
        phoneOtp: "",
        userDetails: {},
        error: true,
      };
    case GET_RECENT_DETAILS_VERIFICATION_HOOK_REQUEST:
      return {
        ...state,
        userDetails: {},
        error: false,
        loading: true,
      };
    case GET_RECENT_DETAILS_VERIFICATION_HOOK_SUCCESS:
      const userData = get(action.data, "userData", {});
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.data },
        verificationHook: action.data.verificationHook,
        kycEmail: get(userData, "email", ""),
        userPanData: get(userData, "pan", ""),
        userChequeData: get(userData, "bank_account_details", ""),
                        cvlkraEsignedPdf: get(action.data.userData, 'cvlkra_esigned', {}),
        purchasePending: get(userData, 'order_details.purchase_pending', false),
        consentPending: get(userData, 'consent_pending', null),
        oauthPending: get(userData, 'oauth_pending', null),
        portfolioAvailable: get(userData, 'portfolio_available', null),
        skipPortfolioView: get(action.data.userData, 'portfolio_skipped', false),
        error: false,
        loading: false,
        userRiskScore: get(action.data.userData, 'user_risk_score', null),
        omninusStatus: get(action.data.userData, 'omnibus_status', {}),
        ecanDetails: get(action.data.userData, 'ecan_details', {}),
        ibFlag: get(action.data.userData, 'ib_flag', null),
        ckyc_submitted : get(action.data, 'ckyc_submitted', false),
        portfolioDetails:get(action.data.userData, 'portfolio_details', {})
      };
    case GET_RECENT_DETAILS_VERIFICATION_HOOK_FAILED:
      return {
        ...state,
        userDetails: {},
        error: true,
      };
    case GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        getUserDetailLoading:true,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        mfUserDetails: action.data,
        loading: false,
        error: false,
        getUserDetailLoading:false,
      };
    case GET_USER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        getUserDetailLoading:false
      };
    case SET_UPDATE_KYC:
      return {
        ...state,
        setUpdateKYC: action.flag,
      };

    case SET_ACTIVE_STEP_NUMBER:
      return {
        ...state,
        activeStep:action.step,
      };
    case SET_COMPLETED_STEP_NUMBER:
      return {
        ...state,
        completedStepNo:action.step,
      };

    case SET_CHEQUE_IMG_SRC:
      return {
        ...state,
        chequeSrcUrl: action.url,
      };
    case SET_CHEQUE_UPLOAD_STATUS:
      return {
        ...state,
        chequeUploaded: action.flag,
      };
    case RESET_CHEQUE:
      return {
        ...state,
        chequeSelected: action.flag,
      };
    case RESELECT_CHEQUE:
      return {
        ...state,
        chequeUploaded: false,
      };
    case UPDATE_CHEQUE_DATA:
      return {
        ...state,
        userChequeData: action.chequeData,
      };
    case SET_BANK_STATEMENT_IMG_SRC:
      return {
        ...state,
        bankStatementSrcUrl: action.url,
      };
    case TOKEN_EXPIRY:
      return {
        ...state,
        tokenExpiry: true,
        error: false,
      };

      case GO_TO_DASHBOARD_SUCCESS:
      return {
        ...state,
        goToDashboard:true
      };
      




    case GET_CHEQUE_OCR_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        bankDetailsSource: "",
        userChequeData: {},
        error: false,
      };
    case GET_CHEQUE_OCR_DATA_SUCCESS:
      return {
        ...state,
        userChequeData: action.data,
        chequeSelected: true,
        bankDetailsSource: "cheque",
        loading: false,
        error: false,
      };
    case GET_CHEQUE_OCR_DATA_FAILED:
      return {
        ...state,
        bankDetailsSource: "",
        userChequeData: {},
        loading: false,
        error: true,
      };
    case VERIFY_ACCOUNT_REQUEST:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case VERIFY_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case VERIFY_ACCOUNT_FAILED:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case PARSE_BANK_STATEMENT_REQUEST:
      return {
        ...state,
        userChequeData: [],
        error: false,
        loading: true,
        bankDetailsSource: "",
      };
    case PARSE_BANK_STATEMENT_VALIDATION:
      return {
        ...state,
        userChequeData: action.data,
        loading: false,
        bankDetailsSource: "",
        error: false,
      };
    case PARSE_BANK_STATEMENT_SUCCESS:
      return {
        ...state,
        userChequeData: action.data,
        loading: false,
        bankDetailsSource: "bank-statement",
        error: false,
      };
    case PARSE_BANK_STATEMENT_FAILED:
      return {
        ...state,
        userChequeData: [],
        loading: false,
        bankDetailsSource: "",
        error: true,
      };
    case SEND_EMAIL_VERIFICATION_REQUEST:
      return {
        ...state,
        kycEmail: "",
        loading: true,
        error: false,
      };
    case SEND_EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        kycEmail: action.email,
        loading: false,
        error: false,
        emailSuccessResponse: action.data.message
      };
    case SEND_EMAIL_VERIFICATION_FAILED:
      return {
        ...state,
        kycEmail: "",
        loading: false,
        error: true,
        emailResponse: action.error?.response?.status === 501 ? action.error.response.data.message:''
      };
    // case GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST:
    //     return {
    //         ...state,
    //         loading: true,
    //         error: false,
    //     };
    // case GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS:
    //     const userData = get(action.data, 'userData', {});
    //     return {
    //         ...state,
    //         ibFlag:action.data,
    //         loading: false,
    //         error: false,
    //         kycEmail: get(userData, 'email', ''),
    //         userPanData: get(userData, 'pan', ''),
    //         userChequeData: get(userData, 'bank_account_details', ''),
    //     };
    // case GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED:
    //     return {
    //         ...state,
    //         loading: false,
    //         error: true,
    //     };

      case SET_ESIGN_DOC_TYPE:
        return {
            ...state,
            esignDocType: action.data.docType,
        };
        case FETCH_CVLKRA_DOCUMENT_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_CVLKRA_DOCUMENT_SUCCESS:
            return {
                ...state,
                cvlkraGeneratedPdf: action.data,
                error: false,
            };

        case SET_CHEQUE_IMG_SRC:
            return {
                ...state,
                chequeSrcUrl: action.url,
            };
        case SET_CHEQUE_UPLOAD_STATUS:
            return {
                ...state,
                chequeUploaded: action.flag,
            };
        case RESET_CHEQUE:
            return {
                ...state,
                chequeSelected: action.flag,
            };
        case RESELECT_CHEQUE:
            return {
                ...state,
                chequeUploaded: false,
            };
        case UPDATE_CHEQUE_DATA:
            return {
                ...state,
                userChequeData: action.chequeData,
            };

        case TOKEN_EXPIRY:
            return {
                ...state,
                tokenExpiry: true,
                error: false,
            };





        case GET_CHEQUE_OCR_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                bankDetailsSource: '',
                userChequeData: {},
                error: false,
            };
        case GET_CHEQUE_OCR_DATA_SUCCESS:
            return {
                ...state,
                userChequeData: action.data,
                chequeSelected: true,
                bankDetailsSource: 'cheque',
                loading: false,
                error: false,
            };
        case GET_CHEQUE_OCR_DATA_FAILED:
            return {
                ...state,
                bankDetailsSource: '',
                userChequeData: {},
                loading: false,
                error: true,
            };
        case VERIFY_ACCOUNT_REQUEST:
            return {
                ...state,
                error: false,
                loading: true,
            };
        case VERIFY_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
            };
        case VERIFY_ACCOUNT_FAILED:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case PARSE_BANK_STATEMENT_REQUEST:
            return {
                ...state,
                userChequeData: [],
                error: false,
                loading: true,
                bankDetailsSource: '',
            };
        case PARSE_BANK_STATEMENT_VALIDATION:
            return {
                ...state,
                userChequeData: action.data,
                loading: false,
                bankDetailsSource: '',
                error: false,
            };
        case PARSE_BANK_STATEMENT_SUCCESS:
            return {
                ...state,
                userChequeData: action.data,
                loading: false,
                bankDetailsSource: 'bank-statement',
                error: false,
            };
        case PARSE_BANK_STATEMENT_FAILED:
            return {
                ...state,
                userChequeData: [],
                loading: false,
                bankDetailsSource: '',
                error: true,
            };
        case SEND_EMAIL_VERIFICATION_REQUEST:
            return {
                ...state,
                kycEmail:'',
                loading: true,
                error: false,
            };
        case SEND_EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                kycEmail:action.email,
                loading: false,
                error: false,
            };
        case SEND_EMAIL_VERIFICATION_FAILED:
            return {
                ...state,
                kycEmail: '',
                loading: false,
                error: true,
            };
        // case GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST:
        //     return {
        //         ...state,
        //         loading: true,
        //         error: false,
        //     };
        // case GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS:
        //     const userData = get(action.data, 'userData', {});
        //     return {
        //         ...state,
        //         ibFlag:action.data,
        //         loading: false,
        //         error: false,
        //         kycEmail: get(userData, 'email', ''),
        //         userPanData: get(userData, 'pan', ''),
        //         userChequeData: get(userData, 'bank_account_details', ''),
        //     };
        // case GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED:
        //     return {
        //         ...state,
        //         loading: false,
        //         error: true,
        //     };

            case FETCH_ESIGN_DOCUMENT_REQUEST:
              return {
                  ...state,
                  error: false,
                  esignDocument: {},
              };
          case FETCH_ESIGN_DOCUMENT_SUCCESS:
              return {
                  ...state,
                  esignDocument: action.data,
                  error: false,
              };
          case FETCH_ESIGN_DOCUMENT_FAILED:
              return {
                  ...state,
                  esignDocument: {},
                  error: true,
              };
     case VERIFY_ECAN_EXISTS_REQUEST:
        return {
            ...state,
            error: false,
            ecanExists: null,
        };
    case VERIFY_ECAN_EXISTS_SUCCESS:
        return {
            ...state,
            ecanExists: true,
            error: false,
        };
    case ECAN_NOT_EXISTS:
        return {
            ...state,
            ecanExists: false,
            error: false,
        };
    case VERIFY_ECAN_EXISTS_FAILED:
        return {
            ...state,
            ecanExists: null,
            error: true,
        };
    case VERIFY_KYC_EXISTS_REQUEST:
        return {
            ...state,
            error: false,
        };
    case VERIFY_KYC_EXISTS_SUCCESS:
        return {
            ...state,
            kycExists: true,
            error: false,
        };
    case KYC_NOT_EXISTS:
        return {
            ...state,
            kycExists: false,
            error: false,
        };
    case VERIFY_KYC_EXISTS_FAILED:
        return {
            ...state,
            kycExists: null,
            error: true,
        };
    case GET_ECAN_MESSAGES:
        return {
            ...state,
            ecanData: action.data,
        };
    case SET_SKIP_PORTFOLIO_VIEW:
      return {
          ...state,
          skipPortfolioView: action.flag,
      };
      case GET_OMNIBUS_STATUS_REQUEST:
        return {
            ...state,
            error: false,
            omninusStatus: {},
        };
    case GET_OMNIBUS_STATUS_SUCCESS:
        return {
            ...state,
            omninusStatus: action.data,
            error: false,
        };
    case GET_OMNIBUS_STATUS_FAILED:
        return {
            ...state,
            omninusStatus: {},
            error: true,
        };
    case OPEN_PROFILE:
    return{
      ...state,
      [action.data.type]:action.data.flag
    }
    case SET_SELECTED_FUNDS:
      return {
          ...state,
          selectedFunds: action.data,
      };
    case OPEN_CKYC:
      return {
        ...state,
        showCKYC: action.flag
      }

    case OPEN_PORTFOLIO:
      return {
        ...state,
        showPortfolio: action.flag
      }

    case OPEN_OAUTH:
      return {
        ...state,
        showOauth: action.flag
      }
    
    case OPEN_USERPROFILE:
      return{
        ...state,
        showUserProfile: action.flag
      }

    case SUCCESSFULL_SUBMISSION_CKYC:
      return{
        ...state,
        ckyc_submitted: action.flag
      }
      case OPEN_ERROR_MODAL:
      return{
        ...state,
        errorIsOpen:action.data.flag,
        errorContent:action.data.reason
      }
      case CLOSE_ERROR_MODAL:
        return{
          ...state,
          errorIsOpen:false
        }
        case OPEN_LEFT_MODAL:
          return{
            ...state,
            leftModal:true
          }
          case CLOSE_LEFT_MODAL:
            return{
              ...state,
              leftModal:false
            }
            case SET_MODAL_HEADER:
              return{
                ...state,
                modalHeader:action.data
              }
            
    case RESET_EMAIL_ERROR:
      return {
        ...state,
        error: action.data,
        emailResponse:'',
        emailSuccessResponse:'',
      }
      case SCHEDULE_APPOINTMENT_REQUEST:
        return {
            ...state,
            loading: true,
            appointmentScheduled: false,
            error: false,
            errorMessage: "",
        };
    case SCHEDULE_APPOINTMENT_SUCCESS:
        return {
            ...state,
            loading: false,
            appointmentScheduled: true,
            error: false,
            errorMessage: "",
        };
    case SCHEDULE_APPOINTMENT_FAILED:
        return {
            ...state,
            loading: false,
            appointmentScheduled: false,
            error: true,
            errorMessage: getApiErrorMessage(action.error),
        };
    default:
      return state;
  }
};
