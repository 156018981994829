import {
  GET_PORTFOLIO_FUND_LIST_REQUEST,
  GET_PORTFOLIO_FUND_LIST_SUCCESS,
  GET_PORTFOLIO_FUND_LIST_FAILED,
  CLEAR_FUND_DATA_FOR_PAYMENT,
  SET_FUND_DATA_FOR_PAYMENT,
  SET_ARBITRARY_PURCHASE_ORDER_DETAILS,
  SET_ARBITRARY_REDEMPTION_ORDER_DETAILS,
  SELECTED_REDEEM_PORTFOLIO,
  FETCH_SIPS_LIST_REQUEST,
  FETCH_SIPS_LIST_FAILED,
  FETCH_SIPS_LIST_SUCCESS,
  CANCEL_SIP_REQUEST,
  CANCEL_SIP_SUCCESS,
  CANCEL_SIP_FAILED,
} from "../../Constants/mfActionTypes.constants";
import { get } from "lodash";
export const initialStore = {
  portfolioFundList: {},
  portfolioRedeemFundList: {},
  loading: false,
  updatedOrderDetails: [],
  error: false,
  selectedfund: "",
  amount: "",
  fundList: {},
  checked: true,
  selectedMandate: "",
  fundNotAvailable: false,
  redeemOrderDetails: {},
  selectedRedeemPortfolio: {},
  sipcancelstatus:{},
  failToCancelSip:"",
  sipsList:[],
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case GET_PORTFOLIO_FUND_LIST_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_PORTFOLIO_FUND_LIST_SUCCESS:
      return {
        ...state,
        portfolioFundList: get(action.data, "purchase", {}),
        portfolioRedeemFundList: get(action.data, "redeem", {}),
        error: false,
      };
    case GET_PORTFOLIO_FUND_LIST_FAILED:
      return {
        ...state,
        error: true,
      };
    case SET_ARBITRARY_PURCHASE_ORDER_DETAILS:
      return {
        ...state,
        purchaseOrderDetails: action.data,
        updatedOrderDetails: action.data.fundList,
      };

    case SET_FUND_DATA_FOR_PAYMENT:
      return {
        ...state,
        ...action.data,
      };
    case CLEAR_FUND_DATA_FOR_PAYMENT:
      return {
        ...initialStore,
      };
    case SET_ARBITRARY_REDEMPTION_ORDER_DETAILS:
      return {
        ...state,
        redeemOrderDetails: {
          ...state.redeemOrderDetails,
          [action.id]: action.data,
        },
        updatedOrderDetails: action.data.fundList,
      };
    case SELECTED_REDEEM_PORTFOLIO:
      return {
        ...state,
        selectedRedeemPortfolio: action.data,
      };
      case CANCEL_SIP_REQUEST:
        return {
            ...state,
            error: false,
        };
    case CANCEL_SIP_SUCCESS:
        return {
            ...state,
            sipcancelstatus: action.data,
            error: false,
        };
    case CANCEL_SIP_FAILED:
        return {
            ...state,
            failToCancelSip:action.data,
            error: true,
        };
        case FETCH_SIPS_LIST_REQUEST:
          return {
              ...state,
              error: false,
          };
      case FETCH_SIPS_LIST_SUCCESS:
          return {
              ...state,
              sipsList: action.data,
              error: false,
          };
      case FETCH_SIPS_LIST_FAILED:
          return {
              ...state,
              error: true,
          };
    default:
      return state;
  }
};
