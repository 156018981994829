import { get } from 'lodash';

export const setSessionValue = (key: string, value: string) => {
    // Saves user token to window.sessionStorage
    window.sessionStorage.setItem(key, value);
};

export const getSessionValue = (key: string) => {
    // Retrieves the user token from window.sessionStorage
    return window.sessionStorage.getItem(key);
};

export const saveToLocalStorage = (key: string, data: any) => {
    return localStorage.setItem(key, data);
}

export const getFromLocalStorage = (key: string) => {
    return localStorage.getItem(key);
}

export const validateEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const getLatestComponentId = (chatDetails: any) => {
    return chatDetails && chatDetails[chatDetails.length-1].componentId;
}

export const getApiErrorMessage = (error: any) => {
    return get(error, 'response.data.message', 'Something went wrong');
}

export const numberToChar = (data: any) => {
    let a: any = [];
    data.split('').forEach((x: any) => {
        isNaN(parseInt(x)) ? a.push(x) : a.push(String.fromCharCode(parseInt(x) + 65));
    });
    return a.join('');
}

