import ReactGA from "react-ga";

export const initGA = () => {
    // ReactGA.initialize('UA-176604156-1');
    ReactGA.initialize('UA-176127840-1');
}

export const PageView = () => {
    ReactGA.pageview(window.location.pathname);
}

export const GaEvent = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
};
