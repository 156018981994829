import { get } from "lodash";
import {
  PARSE_OFFLINE_KYC_REQUEST,
  PARSE_OFFLINE_KYC_SUCCESS,
  PARSE_OFFLINE_KYC_FAILED,
  PARSE_OFFLINE_KYC_VALIDATION,
  SAVE_USER_DETAILS_REQUEST,
  SAVE_USER_DETAILS_SUCCESS,
  SAVE_USER_DETAILS_FAILED,
  GET_STATE_AND_CITY_REQUEST,
  GET_STATE_AND_CITY_SUCCESS,
  GET_STATE_AND_CITY_FAILED,
  VERIFY_CDSL_DETAILS_REQUEST,
  VERIFY_CDSL_DETAILS_SUCCESS,
  VERIFY_CDSL_DETAILS_FAILED,
  SET_CVLKRA_QUESTIONS_LIST,
  GET_KYC_FORM_DATA_REQUEST,
    GET_KYC_FORM_DATA_SUCCESS,
    GET_KYC_FORM_DATA_FAILED,
    POST_KYC_FORM_DATA_REQUEST, 
    POST_KYC_FORM_DATA_SUCCESS, 
    POST_KYC_FORM_DATA_FAILED,
    SET_KYC_PDF_URL,
    SUBMIT_KYC_FORM_DATA_REQUEST, 
    SUBMIT_KYC_FORM_DATA_SUCCESS, 
    SUBMIT_KYC_FORM_DATA_FAILED,
    PARSE_OFFLINE_AUTHENTICATION_FAILED,
    FECTH_FROM_DIGILOCKER_REQUEST, FECTH_FROM_DIGILOCKER_SUCCESS, DOCUMENTS_NOT_FOUND,  FECTH_FROM_DIGILOCKER_FAILED,
} from "../../Constants/mfActionTypes.constants";
import { getFromLocalStorage } from "../../utils";
import { getApiErrorMessage } from '../../utils/index';
export const initialStore = {
  offlineKycImgUrl: "",
  loading: false,
  error: false,
  offlineKycData: {},
  offlineKycMsg: "",
  cdslUserDetails: {},
  stateCityDetails: {},
  stateCityLoader: false,
  userKycDetails: {},
  cvlkraQuestionsList: [],
  kycformData:{
    application_kyc_data: {
      aadhaar_number: '', 
      cor_add1: '', 
      cor_add2: '', 
      cor_add3: '', 
      cor_add_city: '', 
      cor_add_country: '', 
      cor_add_pincode: "", 
      cor_add_proof: "", 
      cor_add_state: "", 
      date_of_declaration: "", 
      dob: "", 
      document_type: "", 
      email: "", 
      father_name: "", 
      gender: "", 
      ipv_date: "", 
      marital_status: "", 
      mob_no: "", 
      name: "", 
      nationality: "", 
      other_nationality: "", 
      pan_no: "", 
      per_add1: "", 
      per_add2: "", 
      per_add3: "", 
      per_add_city: "", 
      per_add_country: "", 
      per_add_flag: "", 
      per_add_pincode: "", 
      per_add_proof: "", 
      per_add_state: "", 
      place_of_declaration: "", 
      pos_code: "", 
      residential_status: "", 
      spouse_name: "", 
      validity_of_cor_add_proof: "", 
      validity_of_per_add_proof: ""
    }, 
    b64_photo: "",
    signature64:"",
    stamp64:""
  },
  kycPostSubmitStatus:"",
  kycgeneratestatus:"",
  kycPdfUrl: '',
  kycSubmissionPdf: '',   
  kycSubmitStatus: '',   
  kycSubmitErrorMsg: {}, 
  digilockerData: {},
  errorMessage: "",
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case PARSE_OFFLINE_KYC_REQUEST:
      return {
        ...state,
        offlineKycImgUrl: "",
        error: false,
      };
    case PARSE_OFFLINE_KYC_SUCCESS:
      return {
        ...state,
        offlineKycImgUrl: action.data.image_64,
        offlineKycData: action.data,
        error: false,
      };
    case PARSE_OFFLINE_KYC_VALIDATION:
      return {
        ...state,
        offlineKycMsg: action.data.message,
        error: true,
      };
    case PARSE_OFFLINE_KYC_FAILED:
      return {
        ...state,
        offlineKycImgUrl: "",
        error: true,
      };
      case PARSE_OFFLINE_AUTHENTICATION_FAILED:
        return{
          ...state,
          error:true,
          authenticationError:"Please download Aadhar zip file again from UIDAI Website and upload."
        };
    case VERIFY_CDSL_DETAILS_REQUEST:
      return {
        ...state,
        cdslUserDetails: {},
        error: false,
      };
    case VERIFY_CDSL_DETAILS_SUCCESS:
      return {
        ...state,
        cdslUserDetails: action.data,
        error: false,
      };
    case VERIFY_CDSL_DETAILS_FAILED:
      return {
        ...state,
        cdslUserDetails: {},
        error: true,
      };
    case GET_STATE_AND_CITY_REQUEST:
      return {
        ...state,
        stateCityLoader: true,
        error: false,
      };
    case GET_STATE_AND_CITY_SUCCESS:
      return {
        ...state,
        stateCityLoader: false,
        stateCityDetails: action.data,
        error: false,
      };
    case GET_STATE_AND_CITY_FAILED:
      return {
        ...state,
        stateCityLoader: false,
        error: true,
      };
    case SAVE_USER_DETAILS_REQUEST:
      return {
        ...state,
        userKycDetails: {},
        error: false,
      };
    case SAVE_USER_DETAILS_SUCCESS:
      return {
        ...state,
        stateCityLoader: false,
        userKycDetails: action.data,
        error: false,
      };
    case SAVE_USER_DETAILS_FAILED:
      return {
        ...state,
        userKycDetails: {},
        error: true,
      };
    case SET_CVLKRA_QUESTIONS_LIST:
      return {
        ...state,
        cvlkraQuestionsList: action.questionsList,
      };
     
        case POST_KYC_FORM_DATA_REQUEST:
          return {
              ...state,
              loading: true,
              error: false,
          };
      case POST_KYC_FORM_DATA_SUCCESS:
          return {
              ...state,
              loading: false,
              error: false,
              kycSubmissionPdf: action.data.submissionForm,
              kycPostSubmitStatus:"SUCCESS"
          };
      case POST_KYC_FORM_DATA_FAILED:
          return {
              ...state,
              loading: false,
              error:true,
              kycPostSubmitStatus:"FAILED"
          };
      case SUBMIT_KYC_FORM_DATA_REQUEST:
          return {
              ...state,
              loading: true,
              error: false,
          };
      case SUBMIT_KYC_FORM_DATA_SUCCESS:
          return {
              ...state,
              loading: false,
              error: false,
              kycSubmitStatus: "SUCCESS",
          };
      case SUBMIT_KYC_FORM_DATA_FAILED:
          return {
              ...state,
              loading: false,
              error:true,
              kycSubmitErrorMsg: action.data,
              kycSubmitStatus:"FAILED"
          };
      case GET_KYC_FORM_DATA_REQUEST:
          return {
              ...state,
              loading: true,
          };
      case GET_KYC_FORM_DATA_SUCCESS:
          return {
              ...state,
              loading: false,
              kycformData: action.data,
              kycgeneratestatus:"SUCCESS"
          };
      case GET_KYC_FORM_DATA_FAILED:
          return {
              ...state,
              loading: false,
              error:true,
              kycgeneratestatus:"FAILED"
          };
      case SET_KYC_PDF_URL:
          return {
              ...state,
              kycPdfUrl:action.data
          };
          case FECTH_FROM_DIGILOCKER_REQUEST:
            return {
                ...state,
                loading: true,
                digilockerData: {},
                error: false,
                errorMessage: "",
            };
        case FECTH_FROM_DIGILOCKER_SUCCESS:
            return {
                ...state,
                loading: false,
                digilockerData: action.data,
                error: false,
                errorMessage: "",
            };
        case DOCUMENTS_NOT_FOUND:
            return {
                ...state,
                loading: false,
                digilockerData: action.data,
                error: false,
                errorMessage: "",
            };
        case FECTH_FROM_DIGILOCKER_FAILED:
            return {
                ...state,
                loading: false,
                digilockerData: {},
                error: true,
                errorMessage: getApiErrorMessage(action.error),
            };
    default:
      return state;
  }
};
