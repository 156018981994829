import {
    SEND_ADMIN_CLIENT_PHONE_VERIFICATION_REQUEST,
    SEND_ADMIN_CLIENT_PHONE_VERIFICATION_SUCCESS,
    SEND_ADMIN_CLIENT_PHONE_VERIFICATION_FAILED,
    GET_CUSTOMER_NUMBERS_REQUEST,
    GET_CUSTOMER_NUMBERS_SUCCESS,
    GET_CUSTOMER_NUMBERS_FAILED,
    VERIFY_ADMIN_OTP_SUCCESS,
    VERIFY_ADMIN_OTP_REQUEST,
    VERIFY_ADMIN_OTP_FAILED,
  } from "../../Constants/mfActionTypes.constants";
  import { get } from "lodash";
  import {getFromLocalStorage} from "../../utils";
  export const initialStore = {
    loading: false,
    error: false,
    adminPhone: '',
    clientPhoneList: [],
    customerOptions:[],
    phoneOtp:'',
    kycPhone: getFromLocalStorage('phoneNumber') ? getFromLocalStorage('phoneNumber') : '',
    tokenExpiry: false,
    errorMessage:""
 
  };
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        case SEND_ADMIN_CLIENT_PHONE_VERIFICATION_REQUEST:
            return {
                ...state,
                loading:true,
                error:false,
            };
        case SEND_ADMIN_CLIENT_PHONE_VERIFICATION_SUCCESS:
            const updatedClientList = [...state.clientPhoneList, action.data.clientPhone];
            return {
                ...state,
                adminPhone: action.data.adminPhone,
                clientPhoneList: updatedClientList,
                loading:true,
                error:false,
            };
        case SEND_ADMIN_CLIENT_PHONE_VERIFICATION_FAILED:
            return {
                ...state,
                loading:true,
                error:true,
            };
            case  GET_CUSTOMER_NUMBERS_REQUEST:
                return{
                    ...state,loading:true,error:false,errorMessage:""
                };
                case  GET_CUSTOMER_NUMBERS_SUCCESS:
                    return{
                        ...state,
                        loading:false,
                        error:false,
                        customerOptions:action.data,
                        errorMessage:''
                    };

                    case  GET_CUSTOMER_NUMBERS_FAILED:
                        return{
                            ...state,
                            loading:false,
                            error:true,
                            errorMessage:action.error===500? "Sorry, You are not an Admin" :"Something went wrong."
                        };
                        case VERIFY_ADMIN_OTP_REQUEST:
                            return {
                                ...state,
                                phoneOtp:'',
                                error: false,
                            };
                        case VERIFY_ADMIN_OTP_SUCCESS:
                            return {
                                ...state,
                                phoneOtp:action.phoneOtp,
                                kycPhone:action.clientPhoneNo,
                                tokenExpiry: false,
                                error: false,
                            };
                        case VERIFY_ADMIN_OTP_FAILED:
                            return {
                                ...state,
                                phoneOtp: '',
                                error: true,
                            };
      default:
        return state;
    }
  };
  
