import {
  SAVE_IB_DETAILS_FORM_REQUEST,
  SAVE_IB_DETAILS_FORM_SUCCESS,
  SAVE_IB_DETAILS_FORM_FAILED,
  GET_DOCUMENTS_FLAG_REQUEST,
  GET_DOCUMENTS_FLAG_SUCCESS,
  GET_DOCUMENTS_FLAG_FAILED,
  GET_STATE_AND_CITY_REQUEST,
  GET_STATE_AND_CITY_SUCCESS,
  GET_STATE_AND_CITY_FAILED,
  GET_IB_USER_DETAILS_REQUEST,
  GET_IB_USER_DETAILS_SUCCESS,
  GET_IB_USER_DETAILS_FAILED,
  CREATE_IB_ACCOUNT_REQUEST,
  CREATE_IB_ACCOUNT_SUCCESS,
  CREATE_IB_ACCOUNT_FAILED,
  GET_IB_ACCOUNT_STATUS_REQUEST,
  GET_IB_ACCOUNT_STATUS_SUCCESS,
  GET_IB_ACCOUNT_STATUS_FAILED,
  SAVE_IB_UPDATE_STATUS_REQUEST,
  SAVE_IB_UPDATE_STATUS_SUCCESS,
  SAVE_IB_UPDATE_STATUS_FAILED,
  GET_IB_DISCLOSURE_FORMS_REQUEST,
  GET_IB_DISCLOSURE_FORMS_SUCCESS,
  GET_IB_DISCLOSURE_FORMS_FAILED,

  SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST,
  SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS,
  SAVE_SIGNED_DISCLOSURE_FORMS_FAILED,
  ADD_IB_DOCUMENTS_REQUEST,
  ADD_IB_DOCUMENTS_SUCCESS,
  ADD_IB_DOCUMENTS_FAILED,
  GET_IB_UPDATE_STATUS_REQUEST,
  GET_IB_UPDATE_STATUS_SUCCESS,
  GET_IB_UPDATE_STATUS_FAILED,
  SET_DOCUMENTS_MODAL_STATUS,
  SET_BANK_STATEMENT_IMG_SRC,
  SET_DISCLOSURE_MODAL_STATUS,
  GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS,
  GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED,
  GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST,
} from "../../Constants/mfActionTypes.constants";
import { get } from "lodash";
export const initialStore = {
  saveIBDetails: {},
  ibUserFullName: "",
  loading: false,
  error: false,
  documentsList: {},
  stateCityLoader: false,
  ibUserDetails: {},
  createIbStatus: {},
  updateIBDetails: "",
  saveUpdateIBstatus: {},
  ibAccountStatus: {},
  disclosureForms: {},
  savedDisclosureForms: {}, 
  saveIBDocuments: {},
  updateIBstatus: {},
  documentsModalStatus: false,
  bankStatementSrcUrl: "",
  disclosureModalStatus: false,
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case SAVE_IB_DETAILS_FORM_REQUEST:
      return {
        ...state,
        error: false,
      };
    case SAVE_IB_DETAILS_FORM_SUCCESS:
      return {
        ...state,
        saveIBDetails: action.data,
        ibUserFullName: action.fullName,
        error: false,
      };
    case SAVE_IB_DETAILS_FORM_FAILED:
      return {
        ...state,
        error: true,
      };
    case GET_DOCUMENTS_FLAG_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_DOCUMENTS_FLAG_SUCCESS:
      return {
        ...state,
        documentsList: action.data,
        error: false,
      };
    case GET_DOCUMENTS_FLAG_FAILED:
      return {
        ...state,
        error: true,
      };
    case GET_STATE_AND_CITY_REQUEST:
      return {
        ...state,
        stateCityLoader: true,
        error: false,
      };
    case GET_STATE_AND_CITY_SUCCESS:
      return {
        ...state,
        stateCityLoader: false,
        stateCityDetails: action.data,
        error: false,
      };
    case GET_STATE_AND_CITY_FAILED:
      return {
        ...state,
        stateCityLoader: false,
        error: true,
      };
    case GET_IB_USER_DETAILS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_IB_USER_DETAILS_SUCCESS:
      return {
        ...state,
        ibUserDetails: action.data,
        error: false,
      };
    case GET_IB_USER_DETAILS_FAILED:
      return {
        ...state,
        error: true,
      };

    case CREATE_IB_ACCOUNT_REQUEST:
      return {
        ...state,
        error: false,
      };
    case CREATE_IB_ACCOUNT_SUCCESS:
      return {
        ...state,
        createIbStatus: action.data,
        error: false,
      };
    case CREATE_IB_ACCOUNT_FAILED:
      return {
        ...state,
        error: true,
      };
      case SAVE_IB_UPDATE_STATUS_REQUEST:
        return {
            ...state,
            error: false,
        };
    case SAVE_IB_UPDATE_STATUS_SUCCESS:
        return {
            ...state,
            updateIBDetails:'success',
            saveUpdateIBstatus: action.data,
            error: false,
        };
    case SAVE_IB_UPDATE_STATUS_FAILED:
        return {
            ...state,
            error: true,
        };
        case GET_IB_ACCOUNT_STATUS_REQUEST:
            return {
              ...state,
              loading: true,
              error: false,
            };
          case GET_IB_ACCOUNT_STATUS_SUCCESS:
            return {
              ...state,
              ibAccountStatus: action.data,
              loading: false,
              error: false,
            };
          case GET_IB_ACCOUNT_STATUS_FAILED:
            return {
              ...state,
              loading: false,
              error: true,
            };
            case GET_IB_DISCLOSURE_FORMS_REQUEST:
              return {
                ...state,
                error: false,
              };
            case GET_IB_DISCLOSURE_FORMS_SUCCESS:
              return {
                ...state,
                disclosureForms: action.data,
                error: false,
              };
            case GET_IB_DISCLOSURE_FORMS_FAILED:
              return {
                ...state,
                error: true,
              };
               case GET_ODIN_ACCOUNT_STATUS_FLAG_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ODIN_ACCOUNT_STATUS_FLAG_SUCCESS:
            const userData = get(action.data, 'userData', {});
            return {
                ...state,
                ibFlag:action.data,
                loading: false,
                error: false,
                kycEmail: get(userData, 'email', ''),
                userPanData: get(userData, 'pan', ''),
                userChequeData: get(userData, 'bank_account_details', ''),
            };
        case GET_ODIN_ACCOUNT_STATUS_FLAG_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
            };
              case SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST:
                return {
                  ...state,
                  error: false,
                };
              case SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS:
                return {
                  ...state,
                  savedDisclosureForms: action.data,
                  error: false,
                };
              case SAVE_SIGNED_DISCLOSURE_FORMS_FAILED:
                return {
                  ...state,
                  error: true,
                };
                case ADD_IB_DOCUMENTS_REQUEST:
                  return {
                      ...state,
                      error: false,
                  };
              case ADD_IB_DOCUMENTS_SUCCESS:
                  return {
                      ...state,
                      saveIBDocuments: action.data,
                      error: false,
                  };
              case ADD_IB_DOCUMENTS_FAILED:
                  return {
                      ...state,
                      error: true,
                  };
                  case GET_IB_UPDATE_STATUS_REQUEST:
                    return {
                        ...state,
                        error: false,
                    };
                case GET_IB_UPDATE_STATUS_SUCCESS:
                    return {
                        ...state,
                        updateIBstatus: action.data,
                        error: false,
                    };
                case GET_IB_UPDATE_STATUS_FAILED:
                    return {
                        ...state,
                        error: true,
                    };
                    case SET_DOCUMENTS_MODAL_STATUS:
                      return {
                          ...state,
                          documentsModalStatus: action.flag,
                      };
                      case SET_BANK_STATEMENT_IMG_SRC:
                        return {
                          ...state,
                          bankStatementSrcUrl: action.url,
                        };
                        case SET_DISCLOSURE_MODAL_STATUS:
                          return {
                              ...state,
                              disclosureModalStatus: action.flag,
                          };
    default:
      return state;
  }
};
