import {
    UPI_PAYMENT_REQUEST,
    UPI_PAYMENT_SUCCESS,
    UPI_PAYMENT_FAILED,
    APPLY_COUPON_CODE_REQUEST,
    APPLY_COUPON_CODE_SUCCESS,
    APPLY_COUPON_CODE_FAILED,
    INVALID_COUPON,
    COUPON_EXPIRED,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_REQUEST,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_SUCCESS,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_FAILED,
    GENERATE_INVOICE_PDF_REQUEST,
    GENERATE_INVOICE_PDF_SUCCESS,
    GENERATE_INVOICE_PDF_FAILED,
    GET_TRANSACTION_STATUS_REQUEST,
    GET_TRANSACTION_STATUS_PENDING,
    GET_TRANSACTION_STATUS_SUCCESS,
    GET_TRANSACTION_STATUS_FAILED,
} from "../../Constants/mfActionTypes.constants";
import {get} from "lodash";

export const initialStore = {
    portfolio: '',
    transactionList: [],
    orderId:'',
    transactionStatus: '',
    loading: false,
    error: false,
    errorMsg:'',
    portfolioAmount: null,
    couponCodeData: {},
    invoicePdfUrl: '',
    couponCode: '',
    tokenAmount: null,
    vpa: '',
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case UPI_PAYMENT_REQUEST:
            return {
                ...state,
                orderId: '',
                errorMsg: '',
            };
        case UPI_PAYMENT_SUCCESS:
            return {
                ...state,
                orderId: action.data.order_no,
                errorMsg: '',
            };
        case UPI_PAYMENT_FAILED:
            return {
                ...state,
                errorMsg: action.error,
                tokenAmount: action.amount,
                vpa: action.vpa,
            };
        case APPLY_COUPON_CODE_REQUEST:
            return {
                ...state,
                couponCodeData: {},
                loading:true,
                error: false,
            };
        case APPLY_COUPON_CODE_SUCCESS:
            return {
                ...state,
                loading:false,
                couponCodeData: action.data,
                couponCode: action.couponCode,
                error: false,
            };
        case INVALID_COUPON:
            return {
                ...state,
                loading:false,
                couponCodeData: action.data,
                error: true,
            };
        case COUPON_EXPIRED:
            return {
                ...state,
                loading:false,
                couponCodeData: action.data,
                error: true,
            };       
        case APPLY_COUPON_CODE_FAILED:
            return {
                ...state,
                loading:false,
                couponCodeData: {},
                //errorMsg: action.error,
                error: true,
            };

        case GET_RECENT_DETAILS_VERIFICATION_HOOK_REQUEST:
            return {
                ...state,
            };
        case GET_RECENT_DETAILS_VERIFICATION_HOOK_SUCCESS:
            return {
                ...state,
                couponCodeData: get(action.data.userData, 'payment_details', {}),
            };
        case GET_RECENT_DETAILS_VERIFICATION_HOOK_FAILED:
            return {
                ...state,
            };
        case GENERATE_INVOICE_PDF_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GENERATE_INVOICE_PDF_SUCCESS:
            return {
                ...state,
                invoicePdfUrl: action.data.signed_base64,
                error: false,
            };
        case GENERATE_INVOICE_PDF_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_TRANSACTION_STATUS_REQUEST:
            return {
                ...state,
                transactionStatus: 'requested',
            };
        case GET_TRANSACTION_STATUS_PENDING:
            return {
                ...state,
                transactionStatus: 'pending'
            };
        case GET_TRANSACTION_STATUS_SUCCESS:
            return {
                ...state,
                transactionStatus: 'success'
            };
        case GET_TRANSACTION_STATUS_FAILED:
            return {
                ...state,
                transactionStatus: 'failed',
                errorMsg: action.error,
            };
        default:
            return state
    }
}

