import {
    GET_CUSTOMER_SUPPORT_LIST_FAILED,
    GET_CUSTOMER_SUPPORT_LIST_REQUEST,
    GET_CUSTOMER_SUPPORT_LIST_SUCCESS,
    GET_CUSTOMER_SUPPORT_REQUEST,
    GET_CUSTOMER_SUPPORT_SUCCESS,
    GET_CUSTOMER_SUPPORT_FAILED,
    DOWNLOAD_CUSTOMER_ATTACHMENT_REQUEST,
    DOWNLOAD_CUSTOMER_ATTACHMENT_SUCCESS,
    DOWNLOAD_CUSTOMER_ATTACHMENT_FAILED,
} from "../Constants/actionTypes.constants";

export const initialStore = {
    customerSupportList: [],
    currentCustomerSupport: {},
    loading: false,
};



export default (state = initialStore, action) => {
    switch (action.type) {
        case GET_CUSTOMER_SUPPORT_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                customerSupportList: [],
            };
        case GET_CUSTOMER_SUPPORT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                customerSupportList: action.data,
            };
        case GET_CUSTOMER_SUPPORT_LIST_FAILED:
            return {
                ...state,
                loading: false,
                customerSupportList: [],
            };
        case DOWNLOAD_CUSTOMER_ATTACHMENT_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DOWNLOAD_CUSTOMER_ATTACHMENT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DOWNLOAD_CUSTOMER_ATTACHMENT_FAILED:
            return {
                ...state,
                loading: false,
            };
        case GET_CUSTOMER_SUPPORT_REQUEST:
            return {
                ...state,
                currentCustomerSupport: {},
            };
        case GET_CUSTOMER_SUPPORT_SUCCESS:
            return {
                ...state,
                currentCustomerSupport: action.data,
            };
        case GET_CUSTOMER_SUPPORT_FAILED:
            return {
                ...state,
                currentCustomerSupport: {},
            };
        default:
            return state
    }
}

