import {
    GET_POPOTO_DATA_REQUEST,
    GET_POPOTO_SEARCH_SUGGESTIONS_REQUEST,
    GET_POPOTO_SEARCH_SUGGESTIONS_SUCCESS,
    GET_POPOTO_SEARCH_SUGGESTIONS_FAILED,
    SET_POPOTO_SEARCH_SUGGESTIONS,
    GET_TICKER_SYMBOL_REQUEST,
    GET_TICKER_SYMBOL_SUCCESS,
    GET_TICKER_SYMBOL_FAILED,
} from "../Constants/actionTypes.constants";
import { schema } from '../Constants/popotoSchema';

export const initialStore = {
    graphData: [],
    searchSuggestions: [],
    formattedSearchSuggestions: [],
    loading: false,
    error: false,
    tickerSymbol:'',
};

function transformSearchSuggestions(data) {
    const transformedData = data.map(x => {return ({value: x.id, label: (x.title+'-'+x.type)})});
    return transformedData;
}

export default (state = initialStore, action) => {
    switch (action.type) {
        case GET_POPOTO_DATA_REQUEST:
            let clonedSchema = {...schema};
            let typeSchema = clonedSchema[action.data.type];
            typeSchema.value.name=action.data.text;
            return {
                ...state,
                graphData: typeSchema,
                // loading: true,
                // error: false,
            };
        case SET_POPOTO_SEARCH_SUGGESTIONS:
            return {
                ...state,
                searchSuggestions: action.data,
            };
        case GET_POPOTO_SEARCH_SUGGESTIONS_REQUEST:
            return {
                ...state,
                loading: false,
                searchSuggestions: [],
                error: false,
            };
        case GET_POPOTO_SEARCH_SUGGESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                formattedSearchSuggestions: transformSearchSuggestions(action.data),
                searchSuggestions: action.data,
                error: false,
            };
        case GET_POPOTO_SEARCH_SUGGESTIONS_FAILED:
            return {
                ...state,
                searchSuggestions: [],
                error: true,
            };
        case GET_TICKER_SYMBOL_REQUEST:
            return {
                ...state,
                tickerSymbol:'',
                error: false,
            };
        case GET_TICKER_SYMBOL_SUCCESS:
            return {
                ...state,
                tickerSymbol:action.data.ticker_name,
                error: false,
            };
        case GET_TICKER_SYMBOL_FAILED:
            return {
                ...state,
                tickerSymbol:'',
                error: true,
            };
        default:
            return state
    }
}

