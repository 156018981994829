import { get } from "lodash";
import {
  SET_PAN_IMG_SRC,
  SET_PAN_UPLOAD_STATUS,
  RESET_PAN,
  RESELECT_PAN,
  UPDATE_PAN_DATA,
  GET_PAN_OCR_DATA_REQUEST,
  GET_PAN_OCR_DATA_SUCCESS,
  GET_PAN_OCR_DATA_FAILED,
  VERIFY_PAN_DATA_REQUEST,
  VERIFY_PAN_DATA_SUCCESS,
  VERIFY_PAN_DATA_FAILED,
  VERIFY_PAN_DATA_STATUS,
  FETCH_KYC_REQUEST,
  FETCH_KYC_SUCCESS,
  FETCH_KYC_FAILED,
  SEND_USER_CITY_REQUEST,
  SEND_USER_CITY_SUCCESS,
  SEND_USER_CITY_FAILED,
} from "../../Constants/mfActionTypes.constants";
import { getFromLocalStorage } from "../../utils";

export const initialStore = {
  loading: false,
  error: false,

  panSrcUrl: "",
  panUploaded: false,
  userPanData: {},
  panSelected: false,
  panverifyStatus: false,
  panVerifyData: [],

  kycEmail: "",

  fetchKycLoader: false,
  fetchKycStatus: "",
};
export default (state = initialStore, action) => {
  switch (action.type) {
    case SET_PAN_IMG_SRC:
      return {
        ...state,
        panSrcUrl: action.url,
      };
    case SET_PAN_UPLOAD_STATUS:
      return {
        ...state,
        panUploaded: action.flag,
      };
    case RESET_PAN:
      return {
        ...state,
        panSelected: action.flag,
      };
    case RESELECT_PAN:
      return {
        ...state,
        panUploaded: false,
      };
    case UPDATE_PAN_DATA:
      return {
        ...state,
        userPanData: action.panData,
      };
    case GET_PAN_OCR_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        userPanData: {},
        error: false,
      };
    case GET_PAN_OCR_DATA_SUCCESS:
      return {
        ...state,
        userPanData: action.data,
        panSelected: true,
        loading: false,
        error: false,
      };
    case GET_PAN_OCR_DATA_FAILED:
      return {
        ...state,
        userPanData: {},
        loading: false,
        error: true,
      };
    case VERIFY_PAN_DATA_REQUEST:
      return {
        ...state,
        panverifyStatus: true,
        panVerifyData: [],
        error: false,
      };
    case VERIFY_PAN_DATA_SUCCESS:
      return {
        ...state,
        panverifyStatus: false,
        panVerifyData: action.data,
        error: false,
      };
    case VERIFY_PAN_DATA_STATUS:
      return {
        ...state,
        panverifyStatus: false,
        panVerifyData: action.data,
        error: false,
      };
    case VERIFY_PAN_DATA_FAILED:
      return {
        ...state,
        panverifyStatus: false,
        panVerifyData: [],
        error: true,
      };
    case FETCH_KYC_REQUEST:
      return {
        ...state,
        fetchKycLoader: true,
        fetchKycStatus: "",
        error: false,
      };
    case FETCH_KYC_SUCCESS:
      return {
        ...state,
        fetchKycLoader: false,
        fetchKycStatus: action.data,
        error: false,
      };
    case FETCH_KYC_FAILED:
      return {
        ...state,
        fetchKycLoader: false,
        fetchKycStatus: "",
      };
    case SEND_USER_CITY_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case SEND_USER_CITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        birthCity: action.city,
      };
    case SEND_USER_CITY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};
