import {
  SET_ASSET_ALLOCATION_VIEW,
  GET_ASSET_ALLOCATION_REQUEST,
  GET_ASSET_ALLOCATION_SUCCESS,
  GET_ASSET_ALLOCATION_FAILED,
  UPDATE_ASSET_ALLOCATION_REQUEST,
  UPDATE_ASSET_ALLOCATION_SUCCESS,
  UPDATE_ASSET_ALLOCATION_FAILED,
  GET_PORTFOLIO_DISTRIBUTION_REQUEST,
  GET_PORTFOLIO_DISTRIBUTION_SUCCESS,
  GET_PORTFOLIO_DISTRIBUTION_FAILED,
  REBALANCE_ALLOCATION_REQUEST,
  REBALANCE_ALLOCATION_SUCCESS,
  REBALANCE_ALLOCATION_FAILED,
  SET_SKIP_PORTFOLIO_REQUEST,
  SET_SKIP_PORTFOLIO_SUCCESS,
  SET_SKIP_PORTFOLIO_FAILED,
  UPDATE_ASSET_DETAILS_DETAILS,
  GET_MIN_INVESTMENT_REQUEST,
  GET_MIN_INVESTMENT_SUCCESS,
  GET_MIN_INVESTMENT_FAILED,
  ECAN_BANK_DETAILS_REQUEST,
  ECAN_BANK_DETAILS_SUCCESS,
  ECAN_BANK_DETAILS_FAILED,
  SET_SELL_GROUP_ORDER_NO,
  SET_REDEMPTION_WARNING_VIEW,
  UPDATE_ALLOCATION_ORDER_DETAILS_REQUEST,
  UPDATE_ALLOCATION_ORDER_DETAILS_SUCCESS,
  UPDATE_ALLOCATION_ORDER_DETAILS_FAILED,
  SET_PURCHASE_ORDER,
  SET_BUY_GROUP_ORDER_DETAILS,
  SET_BANK_ACCOUNT_MISMATCH,
  FETCH_RECOMMENDATIONS_REQUEST,
  FETCH_RECOMMENDATIONS_SUCCESS,
  FETCH_RECOMMENDATIONS_FAILED,
  SET_PURCHASE_PENDING_REQUEST,
  SET_PURCHASE_PENDING_SUCCESS,
  SET_PURCHASE_PENDING_FAILED,
  SET_REDEMPTION_STATUS,
  SET_ARBITRARY_REDEMPTION_ORDER_DETAILS,
  SET_ARBITRARY_PURCHASE_ORDER_DETAILS
} from "../../Constants/mfActionTypes.constants";

export const initialStore = {
  assetAllocationViewActive: false,
  error: false,
  allocationDetails: {},
  distributionDetails: {},
  updateAllocationDetails: {},
  rebalanceAllocationDetails: {},
  minInvestMentDetails: {},
  ecanBankDetails: {},
  setSellGroupOrderNo: "",
  setPurchaseOrder: false,
  updatedOrderDetails: [],
  setBuyGroupOrderDetails:{},
  bankAccountMismatch: false,
  orderBasket: {},
  purchasePending: false,
  redemptionStatus:true,
  redeemOrderDetails:{},
  redemptionWarningView: false,
  purchaseOrderDetails:{},

};

export default (state = initialStore, action) => {
  switch (action.type) {
    case SET_ASSET_ALLOCATION_VIEW:
      return {
        ...state,
        assetAllocationViewActive: action.flag,
      };
    case GET_ASSET_ALLOCATION_REQUEST:
      return {
        ...state,
        error: false,
        allocationDetails: {},
      };
    case GET_ASSET_ALLOCATION_SUCCESS:
      return {
        ...state,
        allocationDetails: action.data,
        error: false,
      };
    case GET_ASSET_ALLOCATION_FAILED:
      return {
        ...state,
        allocationDetails: {},
        error: true,
      };
    case UPDATE_ASSET_ALLOCATION_REQUEST:
      return {
        ...state,
        error: false,
      };
    case UPDATE_ASSET_ALLOCATION_SUCCESS:
      return {
        ...state,
        error: false,
      };
    case UPDATE_ASSET_ALLOCATION_FAILED:
      return {
        ...state,
        error: true,
      };
    case GET_PORTFOLIO_DISTRIBUTION_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_PORTFOLIO_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        distributionDetails: action.data,
        error: false,
      };
    case GET_PORTFOLIO_DISTRIBUTION_FAILED:
      return {
        ...state,
        distributionDetails: {},
        error: true,
      };
    case SET_SKIP_PORTFOLIO_REQUEST:
      return {
        ...state,
        error: false,
      };
    case SET_SKIP_PORTFOLIO_SUCCESS:
      return {
        ...state,
        skipPortolio: action.data.success,
        error: false,
      };
    case SET_SKIP_PORTFOLIO_FAILED:
      return {
        ...state,
        error: true,
      };
    case REBALANCE_ALLOCATION_REQUEST:
      return {
        ...state,
        error: false,
        loading:true,
      };
    case REBALANCE_ALLOCATION_SUCCESS:
      return {
        ...state,
        rebalanceAllocationDetails: action.data,
        error: false,
        loading:false,
      };
    case REBALANCE_ALLOCATION_FAILED:
      return {
        ...state,
        error: true,
        loading:false,
      };
    case UPDATE_ASSET_DETAILS_DETAILS:
      return {
        ...state,
        updateAllocationDetails: action.updateDetails,
      };
    case GET_MIN_INVESTMENT_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_MIN_INVESTMENT_SUCCESS:
      return {
        ...state,
        minInvestMentDetails: action.data,
        error: false,
      };
    case GET_MIN_INVESTMENT_FAILED:
      return {
        ...state,
        error: true,
      };
    case ECAN_BANK_DETAILS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case ECAN_BANK_DETAILS_SUCCESS:
      return {
        ...state,
        ecanBankDetails: action.data,
        error: false,
      };
    case ECAN_BANK_DETAILS_FAILED:
      return {
        ...state,
        error: true,
      };
    case SET_SELL_GROUP_ORDER_NO:
      return {
        ...state,
        setSellGroupOrderNo: action.orderNo,
      };
    case SET_REDEMPTION_WARNING_VIEW:
      return {
        ...state,
        redemptionWarningView: action.flag,
      };
    case UPDATE_ALLOCATION_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        error: false,
      };
    case UPDATE_ALLOCATION_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        updatedOrderDetails: action.data,
        error: false,
      };
      case UPDATE_ALLOCATION_ORDER_DETAILS_FAILED:
        return {
            ...state,
            error: true,
        };
      case SET_PURCHASE_ORDER:
        return {
            ...state,
            setPurchaseOrder: action.flag,
        };
        case SET_BUY_GROUP_ORDER_DETAILS:
          return {
              ...state,
              setBuyGroupOrderDetails: action.buyDetails,
          };
          case SET_BANK_ACCOUNT_MISMATCH:
            return {
                ...state,
                bankAccountMismatch: action.flag,
            };
            case FETCH_RECOMMENDATIONS_REQUEST:
              return {
                  ...state,
                  error: false,
              };
          case FETCH_RECOMMENDATIONS_SUCCESS:
              return {
                  ...state,
                  orderBasket: action.data,
                  error: false,
              };
          case FETCH_RECOMMENDATIONS_FAILED:
              return {
                  ...state,
                  error: true,
              };
              case SET_PURCHASE_PENDING_REQUEST:
                return {
                    ...state,
                    error: false,
                };
            case SET_PURCHASE_PENDING_SUCCESS:
                return {
                    ...state,
                    purchasePending: action.data,
                    error: false,
                };
            case SET_PURCHASE_PENDING_FAILED:
                return {
                    ...state,
                    error: true,
                };
                case SET_REDEMPTION_STATUS:
            return {
                ...state,
                redemptionStatus: action.status,
            };
    default:
      return state;

    case SET_ARBITRARY_REDEMPTION_ORDER_DETAILS:
      return {
        ...state,
        redeemOrderDetails: { ...state.redeemOrderDetails, [action.id]: action.data },
        updatedOrderDetails: action.data.fundList,
      };
    case SET_ARBITRARY_PURCHASE_ORDER_DETAILS:
      return {
        ...state,
        purchaseOrderDetails: action.data,
        updatedOrderDetails: action.data.fundList,
      };
  }
};
