import {
  SET_CHAT,
  SET_USER_CUSTOM_MESSAGE,
  GET_CHAT_REQUEST,
  GET_CHAT_SUCCESS,
  GET_CHAT_FAILED,
  SEND_USER_MESSAGE_SUCCESS,
  SEND_USER_MESSAGE_FAILED,
  SEND_USER_MESSAGE_REQUEST,
  GET_CUSTOMER_CHAT_REQUEST,
  GET_CUSTOMER_CHAT_SUCCESS,
  GET_CUSTOMER_CHAT_FAILED,
  GET_INITIAL_CHAT_REQUEST,
  GET_INITIAL_CHAT_SUCCESS,
} from "../../Constants/mfActionTypes.constants";
import { get } from "lodash";
export const initialStore = {
  loading: false,
  error: false,
  chatData: [],
  userMessage: "",
  userAttachment: "",
  userFilename: "",
  chatContents: {},
  entityList: [],
  userMessageSent: false,
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case SET_CHAT:
      return {
        ...state,
        chatData: [...state.chatData, action.data],
      };
    case SET_USER_CUSTOM_MESSAGE:
      return {
        ...state,
        userMessage: action.data.message,
        userAttachment: action.data.attachment,
        userFilename: action.data.fileName,
      };
    case GET_CHAT_REQUEST:
      return {
        ...state,
        chatContents: {},
      };
    case GET_CHAT_SUCCESS:
      return {
        ...state,
        chatContents: action.data.data,
        // entityList: action.data.entity_list,
        entityList: get(action.data, "entity_list", []),
      };
    case GET_CHAT_FAILED:
      return {
        ...state,
        chatContents: {},
      };
    case SEND_USER_MESSAGE_REQUEST:
      return {
        ...state,
        userMessageSending: true,
        userMessage: action.data.message,
        userAttachment: action.data.attachment,
        userFilename: action.data.fileName,
        userMessageSent: false,
      };
    case SEND_USER_MESSAGE_SUCCESS:
      return {
        ...state,
        userMessageSending: false,
        userMessage: "",
        userAttachment: "",
        userFilename: "",
        userMessageSent: true,
      };
    case SEND_USER_MESSAGE_FAILED:
      return {
        ...state,
        userMessageSending: false,
        userMessageSent: false,
      };
    case GET_CUSTOMER_CHAT_REQUEST:
      return {
        ...state,
        error: false,
      };
    case GET_CUSTOMER_CHAT_SUCCESS:
      return {
        ...state,
        chatData: [...action.data, ...state.chatData],
        error: false,
      };
    case GET_CUSTOMER_CHAT_FAILED:
      return {
        ...state,
        error: true,
      };
    case GET_INITIAL_CHAT_REQUEST:
      return {
        ...state,
        loading: true,
        erro: false,
      };
    case GET_INITIAL_CHAT_SUCCESS:
      return {
        ...state,
        chatData: action.data.data,
      };
    default:
      return state;
  }
};
