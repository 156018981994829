const Assets=[
    {
        "answer_value": "Upto 3,99,950",
        "answer_id": "Upto 3,99,950",
        "add": true
    },
    {
        "answer_value": "4,00,000-10,00,000",
        "answer_id": "4,00,000-10,00,000",
        "add": true
    },
    {
        "answer_value": "10,00,050-15,00,000",
        "answer_id": "10,00,050-15,00,000",
        "add": true
    },
    {
        "answer_value": "15,00,050-25,00,000",
        "answer_id": "15,00,050-25,00,000",
        "add": true
    },
    {
        "answer_value": "25,00,050-50,00,000",
        "answer_id": "25,00,050-50,00,000",
        "add": true
    },
    {
        "answer_value": "50,00,050-1,25,00,000",
        "answer_id": "50,00,050-1,25,00,000",
        "add": true
    },
    {
        "answer_value": "1,25,00,050-2,50,00,000",
        "answer_id": "1,25,00,050-2,50,00,000",
        "add": true
    },
    {
        "answer_value": "2,50,00,050-5,00,00,000",
        "answer_id": "2,50,00,050-5,00,00,000",
        "add": true
    },
    {
        "answer_value": "5,00,00,050-25,00,00,000",
        "answer_id": "5,00,00,050-25,00,00,000",
        "add": true
    },
    {
        "answer_value": "25,00,00,050 and above",
        "answer_id": "25,00,00,050 and above",
        "add": true
    }
]
const Liabilities= [
    {
        "answer_value": "Upto 5,00,000",
        "answer_id": "Upto 5,00,000",
        "add": true
    },
    {
        "answer_value": "5,00,000-10,00,000",
        "answer_id": "5,00,000-10,00,000",
        "add": true
    },
    {
        "answer_value": "10,00,000-15,00,000",
        "answer_id": "10,00,000-15,00,000",
        "add": true
    },
    {
        "answer_value": "15,00,000-25,00,000",
        "answer_id": "15,00,000-25,00,000",
        "add": true
    },
    {
        "answer_value": "25,00,000-35,00,000",
        "answer_id": "25,00,000-35,00,000",
        "add": true
    },
    {
        "answer_value": "35,00,000 and above",
        "answer_id": "35,00,000 and above",
        "add": true
    }
]
const additional=
   [{
    "question_id": "asset",
    "question_value": "Which of the following represents an approximate value of your assets ?",
    "question_type": "Select",
    "version": 2,
    "answers": Assets,
},{
    "question_id": "liability",
    "question_value": "Please select your Which of the following represents an approximate value of your liabilities ?",
    "question_type": "Select",
    "version": 2,
    "answers": Liabilities
}]

export default additional