import { get } from "lodash";
import {
    SET_SELECTED_GOAL_STATE,
    GET_GOALS_DATA_REQUEST,
    GET_GOALS_DATA_SUCCESS,
    GET_GOALS_DATA_FAILED,
    UPDATE_SELECTED_GOAL_REQUEST,
    UPDATE_SELECTED_GOAL_SUCCESS,
    UPDATE_SELECTED_GOAL_FAILED,
    UPDATE_GOALS_UPLOAD_STATE
} from "../../Constants/mfActionTypes.constants";

export const initialStore = {
    selected: '',
    goalsData: {},
    updatedGoals:false,
    completed:false,
  };

export default (state = initialStore, action) => {
  switch (action.type) {
    case SET_SELECTED_GOAL_STATE:
        return {
          ...state,
          selected: action.data,
        };
  
      case GET_GOALS_DATA_REQUEST:
        return {
          ...state,
  
          error: false,
        };
      case GET_GOALS_DATA_SUCCESS:
        return {
          ...state,
          goalsData: action.data,
          error: false,
        };
      case GET_GOALS_DATA_FAILED:
        return {
          ...state,
  
          error: true,
        };
        case   UPDATE_SELECTED_GOAL_REQUEST:
          return {
            ...state,     
            error: false,
          };
      case  UPDATE_SELECTED_GOAL_SUCCESS:
        return {
          ...state,
          updatedGoals:true,
          error: false,
        };
      case  UPDATE_SELECTED_GOAL_FAILED:
        return {
          ...state,
          updatedGoals:false,
          error: true,
        };
        case UPDATE_GOALS_UPLOAD_STATE:
            return{
                ...state,
                completed:action.data,
            }
      default:
        return state;
  }
};
