import {
    SAVE_CKYC_USER_DETAILS_REQUEST,
    SAVE_CKYC_USER_DETAILS_SUCCESS,
    SAVE_CKYC_USER_DETAILS_FAILED,
    GET_CKYC_USER_DETAILS_REQUEST,
    GET_CKYC_USER_DETAILS_SUCCESS,
    GET_CKYC_USER_DETAILS_FAILED,
    SET_UPDATE_CKYC,
    GET_CKYC_FORM_DATA_REQUEST,
    GET_CKYC_FORM_DATA_SUCCESS,
    GET_CKYC_FORM_DATA_FAILED,
    POST_CKYC_FORM_DATA_REQUEST,
    POST_CKYC_FORM_DATA_SUCCESS,
    POST_CKYC_FORM_DATA_FAILED,
    SET_CKYC_PDF_URL,
    FETCH_CKYC_DOCUMENT_REQUEST,
    FETCH_CKYC_DOCUMENT_SUCCESS,
    FETCH_CKYC_DOCUMENT_FAILED, 
    SUBMIT_CKYC_FORM_REQUEST,
    SUBMIT_CKYC_FORM_SUCCESS,
    SUBMIT_CKYC_FORM_FAILED,
  } from "../../Constants/mfActionTypes.constants";
  import { get } from 'lodash';

  export const initialStore = {
    error: false,
    ckycUserDetails:{},
    setUpdateCKYC:false,
    loading:false,
    ckycformData:{
        "application_kyc_data": {
          applicantType:"New",//Update
          accountType:"Normal",//Normal Simplified(for low risk customers) Small,
          kyc_number:"",
          user_first_name:"",
          user_middle_name:"",
          user_last_name:"",
          swd_first_name:"",
          swd_middle_name:"",
          swd_last_name:"",
          mother_first_name:"",
          mother_middle_name:"",
          mother_last_name:"",
          "aadhaar_number": "", 
          "occupation_sector":"",
          "cor_add1": "", 
          "cor_add2": "", 
          "cor_add3": "", 
          "cor_add_city": "", 
          "cor_add_country": "", 
          "cor_add_pincode": "", 
          "cor_add_proof": "", 
          "cor_add_state": "", 
          "date_of_declaration": "", 
          "occupation_type":"",
          "dob": "", 
          "document_type": "", 
          "email": "", 
          "father_name": "", 
          "gender": "F", 
          "ipv_date": "", 
          "marital_status": "", 
          "mob_no": "", 
          "name": "", 
          "maidan_name": "", 
          "mother_name":"",
          "nationality": "", 
          "other_nationality": "", 
          "pan_no": "", 
          "per_add1": "", 
          "per_add2": "", 
          "per_add3": "", 
          "per_add_city": "", 
          "per_add_country": "", 
          "per_add_flag": "", 
          "per_add_pincode": "", 
          "per_add_proof": "", 
          "per_add_state": "", 
          "place_of_declaration": "", 
          "pos_code": "", 
          "residential_status": "", 
          "spouse_name": "", 
          "validity_of_cor_add_proof": "", 
          "validity_of_per_add_proof": "",
          institution_code :"",
          institution_name :"LOTUSDEW WEALTH AND INVESTMENT ADVISORS PVT LTD "
        }, 
        "b64_photo": "",
         "signature64":"",
         "stamp64":""
    },
    kycgeneratestatus:"",
    kycSubmitStatus:"",
    ckycPdfUrl:'',
    ckycGeneratePdf: '',
    fetchckycGeneratePdf:{},
    ckycSubmitError: false,
    getCkycFormDataLoading:false,
    postCkycFormDataLoading:false,
  };
  
  export default (state = initialStore, action) => {
    switch (action.type) {
        
        case SAVE_CKYC_USER_DETAILS_REQUEST:
            return {
                ...state,
                error: false,
                loading:true,

            };
        case SAVE_CKYC_USER_DETAILS_SUCCESS:
            console.log(action.userData,"userDetails")
            return {
                ...state,
                ckycUserDetails: action.userData,
                error: false,
                loading:false,
               
            };
        case SAVE_CKYC_USER_DETAILS_FAILED:
            return {
                ...state,
                error: true,
                loading:false,

            }; 

        case GET_CKYC_USER_DETAILS_REQUEST:
            return {
                ...state,
                error: false,
                ckycUserDetails: {},
            };
        case GET_CKYC_USER_DETAILS_SUCCESS:
            return {
                ...state,
                ckycUserDetails: action.data,
                error: false,
            };
        case GET_CKYC_USER_DETAILS_FAILED:
            return {
                ...state,
                ckycUserDetails: {},
                error: true,
            };

        case SET_UPDATE_CKYC:
            return {
                ...state,
                setUpdateCKYC: action.flag,
            };
        case GET_CKYC_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                kycgeneratestatus: "",
                getCkycFormDataLoading:true
            };
        case GET_CKYC_FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                ckycformData: action.data,
                kycgeneratestatus: "SUCCESS",
                getCkycFormDataLoading:false,
            };
        case GET_CKYC_FORM_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                kycgeneratestatus: "FAILED",
                getCkycFormDataLoading:false
            };
        case POST_CKYC_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                postCkycFormDataLoading:true,
            }
        case POST_CKYC_FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                ckycGeneratePdf: action.data,
                postCkycFormDataLoading:false,
            };
        case POST_CKYC_FORM_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: true,
                postCkycFormDataLoading:false,
            };
        case SET_CKYC_PDF_URL:
            return {
                ...state,
                ckycPdfUrl: action.data
            };
        case FETCH_CKYC_DOCUMENT_REQUEST:
            return {
                ...state,
                error: false,
            };
        case FETCH_CKYC_DOCUMENT_SUCCESS:
            return {
                ...state,
                fetchckycGeneratePdf: action.data,
                error: false,
            };
        case FETCH_CKYC_DOCUMENT_FAILED:
            return {
                ...state,
                error: true,
            };

        case SUBMIT_CKYC_FORM_REQUEST:
            return {
                ...state,
                ckycSubmitError: false,
                loading:true,
            };
        case SUBMIT_CKYC_FORM_SUCCESS:
            return {
                ...state,
                ckycSubmitError: false,
                loading:false,
            };
        case SUBMIT_CKYC_FORM_FAILED:
            return {
                ...state,
                ckycSubmitError: true,
                loading:false,
            };
        default: // need this for default case
            return state
    }
  };
  