import { combineReducers } from 'redux';
import chatStore from './chatReducer';
import upiStore from './upiPaymentsReducer';
import popotoStore from './popotoReducer';
import appStore from './appReducer';
import chartStore from './chartReducer';
import blogStore from './blogReducer';
import kycFormStore from './kycFormReducer';
import ckycFormStore from './ckycFormReducer';
import adminStore from './adminReducer';
import fundDataForPaymentStore from './fundDataForPaymentReducer';
import mfAppStore from './MfReducer/mfAppReducer';
import mfPanKycStore from './MfReducer/mfKycPan.Reducer';
import mfOfflineKycStore from './MfReducer/mfOfflineKyc.Reducer';
import mfAdvisoryStore from './MfReducer/mfAdvisoryReducer';
import mfRiskStore from './MfReducer/mfRiskReducer';
import mfUpiPayStore from './MfReducer/mfUpiPayReducer';
import mfLinkPortfolioStore from './MfReducer/mfLinkPortfolioReducer';
import mfOauthStore from './MfReducer/mfOauthReducer';
import mfKycEsignStore from './MfReducer/mfKycEsignReducer';
import mfDashboardStore from './MfReducer/mfDashboardReducer';
import mffundDataForPaymentStore from './MfReducer/mffundDataForPaymentReducer';
import mfAssetAllocationStore from './MfReducer/mfAssetAllocationReducer';
import mfBuyRecommendedStore from './MfReducer/mfBuyRecommendedReducer';
import mfInternationalStore from './MfReducer/mfInternational.Reducer';
import mfCustomerStore from './MfReducer/mfCustomerReducer';
import mfAdminStore from './MfReducer/mfAdminLoginReducer';
import mfCkycStore from './MfReducer/mfCkycReducer';
import mfCkycEsignStore from './MfReducer/mfCkycEsignReducer'
import mfGoalStore from './MfReducer/mfGoalReducer';
import mandateStore from './MfReducer/mfMandateReducer';
import mfAutoDebitStore from './MfReducer/mfAutoDebitReducer'
export default combineReducers({
  chatStore,
  appStore,
  popotoStore,
  upiStore,
  chartStore,
  blogStore,
  kycFormStore,
  ckycFormStore,
  adminStore,
  fundDataForPaymentStore,
  mfAppStore,
  mfPanKycStore,
  mfOfflineKycStore,
  mfAdvisoryStore,
  mfRiskStore,
  mfUpiPayStore,
  mfKycEsignStore,
  mfLinkPortfolioStore,
  mfOauthStore,
  mfDashboardStore,
  mfAssetAllocationStore,
  mfBuyRecommendedStore,
  mfInternationalStore,
  mfCustomerStore,
  mfAdminStore,
  mffundDataForPaymentStore,
  mfCkycStore,
  mfCkycEsignStore,
  mfGoalStore,
  mfAutoDebitStore,
  mandateStore
});
