// Action types


export const SET_MODAL_STATUS = 'SET_MODAL_STATUS';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const TOKEN_LOGIN_REQUEST = 'TOKEN_LOGIN_REQUEST';
export const TOKEN_LOGIN_SUCCESS = 'TOKEN_LOGIN_SUCCESS';
export const TOKEN_LOGIN_FAILED = 'TOKEN_LOGIN_FAILED';

export const GET_USER_DETAILS_REQUEST = 'GET_USER_DETAILS_REQUEST';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const GET_ADVISORY_CONTRACT_REQUEST = 'GET_ADVISORY_CONTRACT_REQUEST';
export const GET_ADVISORY_CONTRACT_SUCCESS = 'GET_ADVISORY_CONTRACT_SUCCESS';
export const GET_ADVISORY_CONTRACT_FAILED = 'GET_ADVISORY_CONTRACT_FAILED';
export const FETCH_ADVISORY_SIGNED_PDF_REQUEST = 'FETCH_ADVISORY_SIGNED_PDF_REQUEST';
export const FETCH_ADVISORY_SIGNED_PDF_SUCCESS = 'FETCH_ADVISORY_SIGNED_PDF_SUCCESS';
export const FETCH_ADVISORY_SIGNED_PDF_FAILED = 'FETCH_ADVISORY_SIGNED_PDF_FAILED';

export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_IS_DATA_UPDATED = 'SET_IS_DATA_UPDATED';

export const GET_PAN_OCR_DATA_REQUEST = 'GET_PAN_OCR_DATA_REQUEST';
export const GET_PAN_OCR_DATA_SUCCESS = 'GET_PAN_OCR_DATA_SUCCESS';
export const GET_PAN_OCR_DATA_FAILED = 'GET_PAN_OCR_DATA_FAILED';

export const UPI_PAYMENT_REQUEST = 'UPI_PAYMENT_REQUEST';
export const UPI_PAYMENT_SUCCESS = 'UPI_PAYMENT_SUCCESS';
export const UPI_PAYMENT_FAILED = 'UPI_PAYMENT_FAILED';

export const GET_TRANSACTION_STATUS_REQUEST = 'GET_TRANSACTION_STATUS_REQUEST';
export const GET_TRANSACTION_STATUS_SUCCESS = 'GET_TRANSACTION_STATUS_SUCCESS';
export const GET_TRANSACTION_STATUS_PENDING = 'GET_TRANSACTION_STATUS_PENDING';
export const GET_TRANSACTION_STATUS_FAILED = 'GET_TRANSACTION_STATUS_FAILED';
export const GENERATE_INVOICE_PDF_REQUEST = 'GENERATE_INVOICE_PDF_REQUEST';
export const GENERATE_INVOICE_PDF_SUCCESS = 'GENERATE_INVOICE_PDF_SUCCESS';
export const GENERATE_INVOICE_PDF_FAILED = 'GENERATE_INVOICE_PDF_FAILED';

export const GET_CHEQUE_OCR_DATA_REQUEST = 'GET_CHEQUE_OCR_DATA_REQUEST';
export const GET_CHEQUE_OCR_DATA_SUCCESS = 'GET_CHEQUE_OCR_DATA_SUCCESS';
export const GET_CHEQUE_OCR_DATA_FAILED = 'GET_CHEQUE_OCR_DATA_FAILED';

export const RESELECT_PAN = 'RESELECT_PAN';
export const RESELECT_CHEQUE = 'RESELECT_CHEQUE';

export const GET_VOICE_OTP_REQUEST = 'GET_VOICE_OTP_REQUEST';
export const GET_VOICE_OTP_SUCCESS = 'GET_VOICE_OTP_SUCCESS';
export const GET_VOICE_OTP_FAILED = 'GET_VOICE_OTP_FAILED';

export const SEND_PHONE_VERIFICATION_REQUEST = 'SEND_PHONE_VERIFICATION_REQUEST';
export const SEND_PHONE_VERIFICATION_SUCCESS = 'SEND_PHONE_VERIFICATION_SUCCESS';
export const SEND_PHONE_VERIFICATION_FAILED = 'SEND_PHONE_VERIFICATION_FAILED';

export const SEND_ADMIN_CLIENT_PHONE_VERIFICATION_REQUEST = 'SEND_ADMIN_CLIENT_PHONE_VERIFICATION_REQUEST';
export const SEND_ADMIN_CLIENT_PHONE_VERIFICATION_SUCCESS = 'SEND_ADMIN_CLIENT_PHONE_VERIFICATION_SUCCESS';
export const SEND_ADMIN_CLIENT_PHONE_VERIFICATION_FAILED = 'SEND_ADMIN_CLIENT_PHONE_VERIFICATION_FAILED';

export const SEND_EMAIL_VERIFICATION_REQUEST = 'SEND_EMAIL_VERIFICATION_REQUEST';
export const SEND_EMAIL_VERIFICATION_SUCCESS = 'SEND_EMAIL_VERIFICATION_SUCCESS';
export const SEND_EMAIL_VERIFICATION_FAILED = 'SEND_EMAIL_VERIFICATION_FAILED';

export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

export const VERIFY_ADMIN_OTP_REQUEST = 'VERIFY_ADMIN_OTP_REQUEST';
export const VERIFY_ADMIN_OTP_SUCCESS = 'VERIFY_ADMIN_OTP_SUCCESS';
export const VERIFY_ADMIN_OTP_FAILED = 'VERIFY_ADMIN_OTP_FAILED';

export const VERIFY_ACCOUNT_REQUEST = 'VERIFY_ACCOUNT_REQUEST';
export const VERIFY_ACCOUNT_SUCCESS = 'VERIFY_ACCOUNT_SUCCESS';
export const VERIFY_ACCOUNT_FAILED = 'VERIFY_ACCOUNT_FAILED';

export const ACTIVITY_LOG_REQUEST = 'ACTIVITY_LOG_REQUEST';
export const ACTIVITY_LOG_SUCCESS = 'ACTIVITY_LOG_SUCCESS';
export const ACTIVITY_LOG_FAILED = 'ACTIVITY_LOG_FAILED';

export const SET_USER_CUSTOM_MESSAGE = 'SET_USER_CUSTOM_MESSAGE';

export const PING_REQUEST = 'PING_REQUEST';
export const PING_SUCCESS = 'PING_SUCCESS';
export const PING_FAILED = 'PING_FAILED';

export const GET_CHAT_REQUEST = 'GET_CHAT_REQUEST';
export const GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS';
export const GET_CHAT_FAILED = 'GET_CHAT_FAILED';

export const SET_CHAT = 'SET_CHAT';

export const SET_USER_RECENT_MESSAGES_REQUEST = 'SET_USER_RECENT_MESSAGES_REQUEST';
export const SET_USER_RECENT_MESSAGES_SUCCESS = 'SET_USER_RECENT_MESSAGES_SUCCESS';
export const SET_USER_RECENT_MESSAGES_FAILED = 'SET_USER_RECENT_MESSAGES_FAILED';

export const GET_USER_RECENT_MESSAGES_REQUEST = 'GET_USER_RECENT_MESSAGES_REQUEST';
export const GET_USER_RECENT_MESSAGES_SUCCESS = 'GET_USER_RECENT_MESSAGES_SUCCESS';
export const GET_USER_RECENT_MESSAGES_FAILED = 'GET_USER_RECENT_MESSAGES_FAILED';

export const SEND_USER_MESSAGE_REQUEST = 'SEND_USER_MESSAGE_REQUEST';
export const SEND_USER_MESSAGE_SUCCESS = 'SEND_USER_MESSAGE_SUCCESS';
export const SEND_USER_MESSAGE_FAILED = 'SEND_USER_MESSAGE_FAILED';

export const SET_PAN_IMG_SRC = 'SET_PAN_IMG_SRC';
export const RE_RENDER_CHATBOT = 'RE_RENDER_CHATBOT';
export const SET_PAN_UPLOAD_STATUS = 'SET_PAN_UPLOAD_STATUS';
export const SET_CHEQUE_IMG_SRC = 'SET_CHEQUE_IMG_SRC';
export const SET_CHEQUE_UPLOAD_STATUS = 'SET_CHEQUE_UPLOAD_STATUS';
export const SET_PORTFOLIO = 'SET_PORTFOLIO';
export const UPDATE_PAN_DATA = 'UPDATE_PAN_DATA';
export const UPDATE_CHEQUE_DATA = 'UPDATE_CHEQUE_DATA';
export const SET_BANK_STATEMENT_IMG_SRC = 'SET_BANK_STATEMENT_IMG_SRC';
export const SET_DISCLOSURE_MODAL_STATUS = 'SET_DISCLOSURE_MODAL_STATUS';
export const SET_DOCUMENTS_MODAL_STATUS = 'SET_DOCUMENTS_MODAL_STATUS';

export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED';

export const USER_TRANSACTION_LIST_REQUEST = 'USER_TRANSACTION_LIST_REQUEST';
export const USER_TRANSACTION_LIST_SUCCESS = 'USER_TRANSACTION_LIST_SUCCESS';
export const USER_TRANSACTION_LIST_FAILED = 'USER_TRANSACTION_LIST_FAILED';

export const CANCEL_TRANSACTION_REQUEST = 'CANCEL_TRANSACTION_REQUEST';
export const CANCEL_TRANSACTION_SUCCESS = 'CANCEL_TRANSACTION_SUCCESS';
export const CANCEL_TRANSACTION_FAILED = 'CANCEL_TRANSACTION_FAILED';

export const GET_POPOTO_DATA_REQUEST = 'GET_POPOTO_DATA_REQUEST';
export const SET_POPOTO_SEARCH_SUGGESTIONS = 'SET_POPOTO_SEARCH_SUGGESTIONS';

export const GET_POPOTO_SEARCH_SUGGESTIONS_REQUEST = 'GET_POPOTO_SEARCH_SUGGESTIONS_REQUEST';
export const GET_POPOTO_SEARCH_SUGGESTIONS_SUCCESS = 'GET_POPOTO_SEARCH_SUGGESTIONS_SUCCESS';
export const GET_POPOTO_SEARCH_SUGGESTIONS_FAILED = 'GET_POPOTO_SEARCH_SUGGESTIONS_FAILED';

export const GET_TICKER_SYMBOL_REQUEST = 'GET_TICKER_SYMBOL_REQUEST';
export const GET_TICKER_SYMBOL_SUCCESS = 'GET_TICKER_SYMBOL_SUCCESS';
export const GET_TICKER_SYMBOL_FAILED = 'GET_TICKER_SYMBOL_FAILED';

export const SUBSCRIBE_USER_REQUEST = 'SUBSCRIBE_USER_REQUEST';
export const SUBSCRIBE_USER_SUCCESS = 'SUBSCRIBE_USER_SUCCESS';
export const SUBSCRIBE_USER_FAILED = 'SUBSCRIBE_USER_FAILED';

export const SCHEDULE_APPOINTMENT_REQUEST = 'SCHEDULE_APPOINTMENT_REQUEST';
export const SCHEDULE_APPOINTMENT_SUCCESS = 'SCHEDULE_APPOINTMENT_SUCCESS';
export const SCHEDULE_APPOINTMENT_FAILED = 'SCHEDULE_APPOINTMENT_FAILED';

export const FECTH_FROM_DIGILOCKER_REQUEST = 'FECTH_FROM_DIGILOCKER_REQUEST';
export const FECTH_FROM_DIGILOCKER_SUCCESS = 'FECTH_FROM_DIGILOCKER_SUCCESS';
export const DOCUMENTS_NOT_FOUND = 'DOCUMENTS_NOT_FOUND';
export const FECTH_FROM_DIGILOCKER_FAILED = 'FECTH_FROM_DIGILOCKER_FAILED';

export const GET_QUESTIONS_LIST_REQUEST = 'GET_QUESTIONS_LIST_REQUEST';
export const GET_QUESTIONS_LIST_SUCCESS = 'GET_QUESTIONS_LIST_SUCCESS';
export const GET_QUESTIONS_LIST_FAILED = 'GET_QUESTIONS_LIST_FAILED';

export const SAVE_ANSWERS_REQUEST = 'SAVE_ANSWERS_REQUEST';
export const SAVE_ANSWERS_SUCCESS = 'SAVE_ANSWERS_SUCCESS';
export const SAVE_ANSWERS_FAILED = 'SAVE_ANSWERS_FAILED';

export const CHART_TRACKRECORD_REQUEST = 'CHART_TRACKRECORD_REQUEST';
export const CHART_TRACKRECORD_SUCCESS = 'CHART_TRACKRECORD_SUCCESS';
export const CHART_TRACKRECORD_FAILED = 'CHART_TRACKRECORD_FAILED';

export const INSERT_PAN_DATA_REQUEST = 'INSERT_PAN_DATA_REQUEST';
export const INSERT_PAN_DATA_SUCCESS = 'INSERT_PAN_DATA_SUCCESS';
export const INSERT_PAN_DATA_FAILED = 'INSERT_PAN_DATA_FAILED';

export const VERIFY_PAN_DATA_REQUEST = 'VERIFY_PAN_DATA_REQUEST';
export const VERIFY_PAN_DATA_SUCCESS = 'VERIFY_PAN_DATA_SUCCESS';
export const VERIFY_PAN_DATA_FAILED = 'VERIFY_PAN_DATA_FAILED';
export const VERIFY_PAN_DATA_STATUS = 'VERIFY_PAN_DATA_STATUS';
export const INVALID_PAN_FORMAT = 'INVALID_PAN_FORMAT';

export const FETCH_KYC_REQUEST = 'FETCH_KYC_REQUEST';
export const FETCH_KYC_SUCCESS = 'FETCH_KYC_SUCCESS';
export const FETCH_KYC_FAILED = 'FETCH_KYC_FAILED';

export const PARSE_OFFLINE_KYC_REQUEST = 'PARSE_OFFLINE_KYC_REQUEST';
export const PARSE_OFFLINE_KYC_SUCCESS = 'PARSE_OFFLINE_KYC_SUCCESS';
export const PARSE_OFFLINE_KYC_FAILED = 'PARSE_OFFLINE_KYC_FAILED';
export const USER_NOT_AVAILABLE = 'USER_NOT_AVAILABLE';
export const PARSE_OFFLINE_KYC_VALIDATION = 'PARSE_OFFLINE_KYC_VALIDATION';

export const GET_BLOG_LIST_REQUEST = 'GET_BLOG_LIST_REQUEST';
export const GET_BLOG_LIST_SUCCESS = 'GET_BLOG_LIST_SUCCESS';
export const GET_BLOG_LIST_FAILED = 'GET_BLOG_LIST_FAILED';

export const GET_BLOG_CONTENT_REQUEST = 'GET_BLOG_CONTENT_REQUEST';
export const GET_BLOG_CONTENT_SUCCESS = 'GET_BLOG_CONTENT_SUCCESS';
export const GET_BLOG_CONTENT_FAILED = 'GET_BLOG_CONTENT_FAILED';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const RESET_PAN = 'RESET_PAN';
export const RESET_CHEQUE = 'RESET_CHEQUE';

export const PARSE_BANK_STATEMENT_REQUEST = 'PARSE_BANK_STATEMENT_REQUEST';
export const PARSE_BANK_STATEMENT_VALIDATION = 'PARSE_BANK_STATEMENT_VALIDATION';
export const PARSE_BANK_STATEMENT_SUCCESS = 'PARSE_BANK_STATEMENT_SUCCESS';
export const PARSE_BANK_STATEMENT_FAILED = 'PARSE_BANK_STATEMENT_FAILED';
export const GET_ECAN_MESSAGES = 'GET_ECAN_MESSAGES';

export const SEND_USER_CITY_REQUEST = 'SEND_USER_CITY_REQUEST';
export const SEND_USER_CITY_SUCCESS = 'SEND_USER_CITY_SUCCESS';
export const SEND_USER_CITY_FAILED = 'SEND_USER_CITY_FAILED';

export const TOKEN_EXPIRY = 'TOKEN_EXPIRY';
export const SET_RE_LOGIN_MODAL_STATUS = 'SET_RE_LOGIN_MODAL_STATUS';

export const VERIFY_FACE_COMPARE_REQUEST = 'VERIFY_FACE_COMPARE_REQUEST';
export const VERIFY_FACE_COMPARE_SUCCESS = 'VERIFY_FACE_COMPARE_SUCCESS';
export const FACES_DOES_NOT_MATCH = 'FACES_DOES_NOT_MATCH';
export const VERIFY_FACE_COMPARE_FAILED = 'VERIFY_FACE_COMPARE_FAILED';

export const FETCH_KYC_EMAIL_REQUEST = 'FETCH_KYC_EMAIL_REQUEST';
export const FETCH_KYC_EMAIL_SUCCESS = 'FETCH_KYC_EMAIL_SUCCESS';
export const UNSUPPORTED_AUTO_EMAIL = 'UNSUPPORTED_AUTO_EMAIL';
export const FETCH_KYC_EMAIL_FAILED = 'FETCH_KYC_EMAIL_FAILED';

export const SEND_OAUTH_EMAIL_REQUEST = 'SEND_OAUTH_EMAIL_REQUEST';
export const SEND_OAUTH_EMAIL_SUCCESS = 'SEND_OAUTH_EMAIL_SUCCESS';
export const SEND_OAUTH_EMAIL_FAILED = 'SEND_OAUTH_EMAIL_FAILED';

export const DOWNLOAD_UNSIGNED_DOC_REQUEST = 'DOWNLOAD_UNSIGNED_DOC_REQUEST';
export const DOWNLOAD_UNSIGNED_DOC_SUCCESS = 'DOWNLOAD_UNSIGNED_DOC_SUCCESS';
export const DOWNLOAD_UNSIGNED_DOC_FAILED = 'DOWNLOAD_UNSIGNED_DOC_FAILED';

export const VERIFY_ECAN_EXISTS_REQUEST = 'VERIFY_ECAN_EXISTS_REQUEST';
export const VERIFY_ECAN_EXISTS_SUCCESS = 'VERIFY_ECAN_EXISTS_SUCCESS';
export const ECAN_NOT_EXISTS = 'ECAN_NOT_EXISTS';
export const VERIFY_ECAN_EXISTS_FAILED = 'VERIFY_ECAN_EXISTS_FAILED';

export const SET_PAYEZ_STATUS_DETAILS = 'SET_PAYEZ_STATUS_DETAILS';
export const SET_ESIGN_DOC_TYPE = 'SET_ESIGN_DOC_TYPE';
export const SET_ARBITRARY_REDEMPTION_ORDER_DETAILS = 'SET_ARBITRARY_REDEMPTION_ORDER_DETAILS';
export const SET_ARBITRARY_PURCHASE_ORDER_DETAILS = 'SET_ARBITRARY_PURCHASE_ORDER_DETAILS';

export const GET_PAYEZ_STATUS_REQUEST = 'GET_PAYEZ_STATUS_REQUEST';
export const GET_PAYEZ_STATUS_SUCCESS = 'GET_PAYEZ_STATUS_SUCCESS';
export const GET_PAYEZ_STATUS_FAILED = 'GET_PAYEZ_STATUS_FAILED';

export const VERIFY_CDSL_DETAILS_REQUEST = 'VERIFY_CDSL_DETAILS_REQUEST';
export const VERIFY_CDSL_DETAILS_SUCCESS = 'VERIFY_CDSL_DETAILS_SUCCESS';
export const VERIFY_CDSL_DETAILS_FAILED = 'VERIFY_CDSL_DETAILS_FAILED';
export const SET_CVLKRA_QUESTIONS_LIST = 'SET_CVLKRA_QUESTIONS_LIST';

export const GET_STATE_AND_CITY_REQUEST = 'GET_STATE_AND_CITY_REQUEST';
export const GET_STATE_AND_CITY_SUCCESS = 'GET_STATE_AND_CITY_SUCCESS';
export const GET_STATE_AND_CITY_FAILED = 'GET_STATE_AND_CITY_FAILED';

export const SAVE_USER_DETAILS_REQUEST = 'SAVE_USER_DETAILS_REQUEST';
export const SAVE_USER_DETAILS_SUCCESS = 'SAVE_USER_DETAILS_SUCCESS';
export const SAVE_USER_DETAILS_FAILED = 'SAVE_USER_DETAILS_FAILED';

export const VERIFY_KYC_EXISTS_REQUEST = 'VERIFY_KYC_EXISTS_REQUEST';
export const VERIFY_KYC_EXISTS_SUCCESS = 'VERIFY_KYC_EXISTS_SUCCESS';
export const KYC_NOT_EXISTS = 'KYC_NOT_EXISTS';
export const VERIFY_KYC_EXISTS_FAILED = 'VERIFY_KYC_EXISTS_FAILED';

export const UPDATE_RISK_SCORE_REQUEST = 'UPDATE_RISK_SCORE_REQUEST';
export const UPDATE_RISK_SCORE_SUCCESS = 'UPDATE_RISK_SCORE_SUCCESS';
export const UPDATE_RISK_SCORE_FAILED = 'UPDATE_RISK_SCORE_FAILED';

export const APPLY_COUPON_CODE_REQUEST = 'APPLY_COUPON_CODE_REQUEST';
export const APPLY_COUPON_CODE_SUCCESS = 'APPLY_COUPON_CODE_SUCCESS';
export const INVALID_COUPON = 'INVALID_COUPON';
export const COUPON_EXPIRED = 'COUPON_EXPIRED';
export const APPLY_COUPON_CODE_FAILED = 'APPLY_COUPON_CODE_FAILED';

export const GET_KYC_FORM_DATA_REQUEST = 'GET_KYC_FORM_DATA_REQUEST';
export const GET_KYC_FORM_DATA_SUCCESS = 'GET_KYC_FORM_DATA_SUCCESS';
export const GET_KYC_FORM_DATA_FAILED = 'GET_KYC_FORM_DATA_FAILED';
export const POST_KYC_FORM_DATA_REQUEST='POST_KYC_FORM_DATA_REQUEST';
export const POST_KYC_FORM_DATA_SUCCESS='POST_KYC_FORM_DATA_SUCCESS';
export const POST_KYC_FORM_DATA_FAILED='POST_KYC_FORM_DATA_FAILED';
export const SUBMIT_KYC_FORM_DATA_REQUEST='SUBMIT_KYC_FORM_DATA_REQUEST';
export const SUBMIT_KYC_FORM_DATA_SUCCESS='SUBMIT_KYC_FORM_DATA_SUCCESS';
export const SUBMIT_KYC_FORM_DATA_FAILED='SUBMIT_KYC_FORM_DATA_FAILED';

export const UPLOAD_PORTFOLIO_REQUEST = 'UPLOAD_PORTFOLIO_REQUEST';
export const UPLOAD_PORTFOLIO_SUCCESS = 'UPLOAD_PORTFOLIO_SUCCESS';
export const UPLOAD_PORTFOLIO_FAILED = 'UPLOAD_PORTFOLIO_FAILED';
export const SET_KYC_PDF_URL = 'SET_KYC_PDF_URL';

export const SET_DASHBOARD_VIEW = 'SET_DASHBOARD_VIEW';

export const GET_PORTFOLIO_VIEW_REQUEST = 'GET_PORTFOLIO_VIEW_REQUEST';
export const GET_PORTFOLIO_VIEW_SUCCESS = 'GET_PORTFOLIO_VIEW_SUCCESS';
export const GET_PORTFOLIO_VIEW_FAILED = 'GET_PORTFOLIO_VIEW_FAILED';
export const FETCH_SECTOR_DISTRIBUTION_REQUEST = 'FETCH_SECTOR_DISTRIBUTION_REQUEST';
export const FETCH_SECTOR_DISTRIBUTION_SUCCESS = 'FETCH_SECTOR_DISTRIBUTION_SUCCESS';
export const FETCH_SECTOR_DISTRIBUTION_FAILED = 'FETCH_SECTOR_DISTRIBUTION_FAILED';

export const SET_PORTFOLIO_SIP_REQUEST = 'SET_PORTFOLIO_SIP_REQUEST';
export const SET_PORTFOLIO_SIP_SUCCESS = 'SET_PORTFOLIO_SIP_SUCCESS';
export const SET_PORTFOLIO_SIP_FAILED = 'SET_PORTFOLIO_SIP_FAILED';
export const FETCH_PORTFOLIO_PDF_REQUEST = 'FETCH_PORTFOLIO_PDF_REQUEST';
export const FETCH_PORTFOLIO_PDF_SUCCESS = 'FETCH_PORTFOLIO_PDF_SUCCESS';
export const FETCH_PORTFOLIO_PDF_FAILED = 'FETCH_PORTFOLIO_PDF_FAILED';

export const SET_ASSET_ALLOCATION_VIEW = 'SET_ASSET_ALLOCATION_VIEW';
export const SET_SKIP_PORTFOLIO_VIEW = 'SET_SKIP_PORTFOLIO_VIEW';
export const SET_REDEMPTION_WARNING_VIEW = 'SET_REDEMPTION_WARNING_VIEW';
export const SET_BANK_ACCOUNT_MISMATCH = 'SET_BANK_ACCOUNT_MISMATCH';
export const UPDATE_ASSET_DETAILS_DETAILS = 'UPDATE_ASSET_DETAILS_DETAILS';
export const SET_PURCHASE_ORDER = 'SET_PURCHASE_ORDER';
export const SET_SELL_GROUP_ORDER_NO = 'SET_SELL_GROUP_ORDER_NO';
export const SET_BUY_GROUP_ORDER_DETAILS = 'SET_BUY_GROUP_ORDER_DETAILS';
export const SET_REDEMPTION_STATUS = 'SET_REDEMPTION_STATUS';

export const GET_ASSET_ALLOCATION_REQUEST = 'GET_ASSET_ALLOCATION_REQUEST';
export const GET_ASSET_ALLOCATION_SUCCESS = 'GET_ASSET_ALLOCATION_SUCCESS';
export const GET_ASSET_ALLOCATION_FAILED = 'GET_ASSET_ALLOCATION_FAILED';

export const GET_PORTFOLIO_DISTRIBUTION_REQUEST = 'GET_PORTFOLIO_DISTRIBUTION_REQUEST';
export const GET_PORTFOLIO_DISTRIBUTION_SUCCESS = 'GET_PORTFOLIO_DISTRIBUTION_SUCCESS';
export const GET_PORTFOLIO_DISTRIBUTION_FAILED = 'GET_PORTFOLIO_DISTRIBUTION_FAILED';

export const UPDATE_ASSET_ALLOCATION_REQUEST = 'UPDATE_ASSET_ALLOCATION_REQUEST';
export const UPDATE_ASSET_ALLOCATION_SUCCESS = 'UPDATE_ASSET_ALLOCATION_SUCCESS';
export const UPDATE_ASSET_ALLOCATION_FAILED = 'UPDATE_ASSET_ALLOCATION_FAILED';

export const GET_MIN_INVESTMENT_REQUEST = 'GET_MIN_INVESTMENT_REQUEST';
export const GET_MIN_INVESTMENT_SUCCESS = 'GET_MIN_INVESTMENT_SUCCESS';
export const GET_MIN_INVESTMENT_FAILED = 'GET_MIN_INVESTMENT_FAILED';

export const SAVE_VIDEO_KYC_REQUEST = 'SAVE_VIDEO_KYC_REQUEST';
export const SAVE_VIDEO_KYC_SUCCESS = 'SAVE_VIDEO_KYC_SUCCESS';
export const SAVE_VIDEO_KYC_FAILED = 'SAVE_VIDEO_KYC_FAILED';

export const GET_CKYC_FORM_DATA_FAILED="GET_CKYC_FORM_DATA_FAILED";
export const GET_CKYC_FORM_DATA_REQUEST="GET_CKYC_FORM_DATA_REQUEST";
export const GET_CKYC_FORM_DATA_SUCCESS="GET_CKYC_FORM_DATA_SUCCESS";
export const POST_CKYC_FORM_DATA_STATUS="POST_CKYC_FORM_DATA_STATUS";
export const SET_CKYC_PDF_URL="SET_CKYC_PDF_URL";
export const SET_UPDATE_KYC="SET_UPDATE_KYC";
export const SET_UPDATE_CKYC="SET_UPDATE_CKYC";
export const GET_ESIGN_XML_REQUEST = 'GET_ESIGN_XML_REQUEST';
export const GET_ESIGN_XML_SUCCESS = 'GET_ESIGN_XML_SUCCESS';
export const GET_ESIGN_XML_FAILED = 'GET_ESIGN_XML_FAILED';

export const FETCH_ESIGN_DOCUMENT_REQUEST = 'FETCH_ESIGN_DOCUMENT_REQUEST';
export const FETCH_ESIGN_DOCUMENT_SUCCESS = 'FETCH_ESIGN_DOCUMENT_SUCCESS';
export const FETCH_ESIGN_DOCUMENT_FAILED = 'FETCH_ESIGN_DOCUMENT_FAILED';

export const GET_OMNIBUS_STATUS_REQUEST = 'GET_OMNIBUS_STATUS_REQUEST';
export const GET_OMNIBUS_STATUS_SUCCESS = 'GET_OMNIBUS_STATUS_SUCCESS';
export const GET_OMNIBUS_STATUS_FAILED = 'GET_OMNIBUS_STATUS_FAILED';

export const SET_SKIP_PORTFOLIO_REQUEST = 'SET_SKIP_PORTFOLIO_REQUEST';
export const SET_SKIP_PORTFOLIO_SUCCESS = 'SET_SKIP_PORTFOLIO_SUCCESS';
export const SET_SKIP_PORTFOLIO_FAILED = 'SET_SKIP_PORTFOLIO_FAILED';

export const REBALANCE_ALLOCATION_REQUEST = 'REBALANCE_ALLOCATION_REQUEST';
export const REBALANCE_ALLOCATION_SUCCESS = 'REBALANCE_ALLOCATION_SUCCESS';
export const REBALANCE_ALLOCATION_FAILED = 'REBALANCE_ALLOCATION_FAILED';
export const UPDATE_ALLOCATION_ORDER_DETAILS_REQUEST = 'UPDATE_ALLOCATION_ORDER_DETAILS_REQUEST';
export const UPDATE_ALLOCATION_ORDER_DETAILS_SUCCESS = 'UPDATE_ALLOCATION_ORDER_DETAILS_SUCCESS';
export const UPDATE_ALLOCATION_ORDER_DETAILS_FAILED = 'UPDATE_ALLOCATION_ORDER_DETAILS_FAILED';
export const GET_CONSENT_STATUS_REQUEST = 'GET_CONSENT_STATUS_REQUEST';
export const GET_CONSENT_STATUS_SUCCESS = 'GET_CONSENT_STATUS_SUCCESS';
export const GET_CONSENT_STATUS_FAILED = 'GET_CONSENT_STATUS_FAILED';
export const GET_HOLDINGS_CONSENT_REQUEST = 'GET_HOLDINGS_CONSENT_REQUEST';
export const GET_HOLDINGS_CONSENT_SUCCESS = 'GET_HOLDINGS_CONSENT_SUCCESS';
export const GET_HOLDINGS_CONSENT_FAILED = 'GET_HOLDINGS_CONSENT_FAILED';

export const SET_PURCHASE_PENDING_REQUEST = 'SET_PURCHASE_PENDING_REQUEST';
export const SET_PURCHASE_PENDING_SUCCESS = 'SET_PURCHASE_PENDING_SUCCESS';
export const SET_PURCHASE_PENDING_FAILED = 'SET_PURCHASE_PENDING_FAILED';
export const FETCH_RECOMMENDATIONS_REQUEST = 'FETCH_RECOMMENDATIONS_REQUEST';
export const FETCH_RECOMMENDATIONS_SUCCESS = 'FETCH_RECOMMENDATIONS_SUCCESS';
export const FETCH_RECOMMENDATIONS_FAILED = 'FETCH_RECOMMENDATIONS_FAILED';

export const ECAN_BANK_DETAILS_REQUEST = 'ECAN_BANK_DETAILS_REQUEST';
export const ECAN_BANK_DETAILS_SUCCESS = 'ECAN_BANK_DETAILS_SUCCESS';
export const ECAN_BANK_DETAILS_FAILED = 'ECAN_BANK_DETAILS_FAILED';

export const GET_CKYC_USER_DETAILS_REQUEST = 'GET_CKYC_USER_DETAILS_REQUEST';
export const GET_CKYC_USER_DETAILS_SUCCESS = 'GET_CKYC_USER_DETAILS_SUCCESS';
export const GET_CKYC_USER_DETAILS_FAILED = 'GET_CKYC_USER_DETAILS_FAILED';
export const POST_CKYC_FORM_DATA_REQUEST = 'POST_CKYC_FORM_DATA_REQUEST';
export const POST_CKYC_FORM_DATA_SUCCESS = 'POST_CKYC_FORM_DATA_SUCCESS';
export const POST_CKYC_FORM_DATA_FAILED = 'POST_CKYC_FORM_DATA_FAILED';

export const SAVE_CKYC_USER_DETAILS_REQUEST = 'SAVE_CKYC_USER_DETAILS_REQUEST';
export const SAVE_CKYC_USER_DETAILS_SUCCESS = 'SAVE_CKYC_USER_DETAILS_SUCCESS';
export const SAVE_CKYC_USER_DETAILS_FAILED = 'SAVE_CKYC_USER_DETAILS_FAILED';

export const GET_SCHEME_LIST_REQUEST = 'GET_SCHEME_LIST_REQUEST';
export const GET_SCHEME_LIST_SUCCESS = 'GET_SCHEME_LIST_SUCCESS';
export const GET_SCHEME_LIST_FAILED = 'GET_SCHEME_LIST_FAILED';
export const SET_SELECTED_FUNDS = 'SET_SELECTED_FUNDS';

export const GET_IB_DISCLOSURE_FORMS_REQUEST = 'GET_IB_DISCLOSURE_FORMS_REQUEST';
export const GET_IB_DISCLOSURE_FORMS_SUCCESS = 'GET_IB_DISCLOSURE_FORMS_SUCCESS';
export const GET_IB_DISCLOSURE_FORMS_FAILED = 'GET_IB_DISCLOSURE_FORMS_FAILED';
export const SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST = 'SAVE_SIGNED_DISCLOSURE_FORMS_REQUEST';
export const SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS = 'SAVE_SIGNED_DISCLOSURE_FORMS_SUCCESS';
export const SAVE_SIGNED_DISCLOSURE_FORMS_FAILED = 'SAVE_SIGNED_DISCLOSURE_FORMS_FAILED';
export const GET_DOCUMENTS_FLAG_REQUEST = 'GET_DOCUMENTS_FLAG_REQUEST';
export const GET_DOCUMENTS_FLAG_SUCCESS = 'GET_DOCUMENTS_FLAG_SUCCESS';
export const GET_DOCUMENTS_FLAG_FAILED = 'GET_DOCUMENTS_FLAG_FAILED';
export const SAVE_IB_DETAILS_FORM_REQUEST = 'SAVE_IB_DETAILS_FORM_REQUEST';
export const SAVE_IB_DETAILS_FORM_SUCCESS = 'SAVE_IB_DETAILS_FORM_SUCCESS';
export const SAVE_IB_DETAILS_FORM_FAILED = 'SAVE_IB_DETAILS_FORM_FAILED';
export const ADD_IB_DOCUMENTS_REQUEST = 'ADD_IB_DOCUMENTS_REQUEST';
export const ADD_IB_DOCUMENTS_SUCCESS = 'ADD_IB_DOCUMENTS_SUCCESS';
export const ADD_IB_DOCUMENTS_FAILED = 'ADD_IB_DOCUMENTS_FAILED';
export const CREATE_IB_ACCOUNT_REQUEST = 'CREATE_IB_ACCOUNT_REQUEST';
export const CREATE_IB_ACCOUNT_SUCCESS = 'CREATE_IB_ACCOUNT_SUCCESS';
export const CREATE_IB_ACCOUNT_FAILED = 'CREATE_IB_ACCOUNT_FAILED';
export const GET_IB_UPDATE_STATUS_REQUEST = 'GET_IB_UPDATE_STATUS_REQUEST';
export const GET_IB_UPDATE_STATUS_SUCCESS = 'GET_IB_UPDATE_STATUS_SUCCESS';
export const GET_IB_UPDATE_STATUS_FAILED = 'GET_IB_UPDATE_STATUS_FAILED';
export const SAVE_IB_UPDATE_STATUS_REQUEST = 'SAVE_IB_UPDATE_STATUS_REQUEST';
export const SAVE_IB_UPDATE_STATUS_SUCCESS = 'SAVE_IB_UPDATE_STATUS_SUCCESS';
export const SAVE_IB_UPDATE_STATUS_FAILED = 'SAVE_IB_UPDATE_STATUS_FAILED';
export const GET_IB_ACCOUNT_STATUS_REQUEST = 'GET_IB_ACCOUNT_STATUS_REQUEST';
export const GET_IB_ACCOUNT_STATUS_SUCCESS = 'GET_IB_ACCOUNT_STATUS_SUCCESS';
export const GET_IB_ACCOUNT_STATUS_FAILED = 'GET_IB_ACCOUNT_STATUS_FAILED';
export const GET_IB_USER_DETAILS_REQUEST = 'GET_IB_USER_DETAILS_REQUEST';
export const GET_IB_USER_DETAILS_SUCCESS = 'GET_IB_USER_DETAILS_SUCCESS';
export const GET_IB_USER_DETAILS_FAILED = 'GET_IB_USER_DETAILS_FAILED';

export const GET_CUSTOMER_SUPPORT_LIST_REQUEST = 'GET_CUSTOMER_SUPPORT_LIST_REQUEST';
export const GET_CUSTOMER_SUPPORT_LIST_SUCCESS = 'GET_CUSTOMER_SUPPORT_LIST_SUCCESS';
export const GET_CUSTOMER_SUPPORT_LIST_FAILED = 'GET_CUSTOMER_SUPPORT_LIST_FAILED';

export const GET_CUSTOMER_SUPPORT_REQUEST = 'GET_CUSTOMER_SUPPORT_REQUEST';
export const GET_CUSTOMER_SUPPORT_SUCCESS = 'GET_CUSTOMER_SUPPORT_SUCCESS';
export const GET_CUSTOMER_SUPPORT_FAILED = 'GET_CUSTOMER_SUPPORT_FAILED';

export const UPDATE_CUSTOMER_SUPPORT_REQUEST = 'UPDATE_CUSTOMER_SUPPORT_REQUEST';
export const UPDATE_CUSTOMER_SUPPORT_SUCCESS = 'UPDATE_CUSTOMER_SUPPORT_SUCCESS';
export const UPDATE_CUSTOMER_SUPPORT_FAILED = 'UPDATE_CUSTOMER_SUPPORT_FAILED';

export const DOWNLOAD_CUSTOMER_ATTACHMENT_REQUEST = 'DOWNLOAD_CUSTOMER_ATTACHMENT_REQUEST';
export const DOWNLOAD_CUSTOMER_ATTACHMENT_SUCCESS = 'DOWNLOAD_CUSTOMER_ATTACHMENT_SUCCESS';
export const DOWNLOAD_CUSTOMER_ATTACHMENT_FAILED = 'DOWNLOAD_CUSTOMER_ATTACHMENT_FAILED';

export const GET_PORTFOLIO_FUND_LIST_REQUEST = 'GET_PORTFOLIO_FUND_LIST_REQUEST';
export const GET_PORTFOLIO_FUND_LIST_SUCCESS = 'GET_PORTFOLIO_FUND_LIST_SUCCESS';
export const GET_PORTFOLIO_FUND_LIST_FAILED = 'GET_PORTFOLIO_FUND_LIST_FAILED';

export const FETCH_CVLKRA_DOCUMENT_REQUEST = 'FETCH_CVLKRA_DOCUMENT_REQUEST';
export const FETCH_CVLKRA_DOCUMENT_SUCCESS = 'FETCH_CVLKRA_DOCUMENT_SUCCESS';
export const FETCH_CVLKRA_DOCUMENT_FAILED = 'FETCH_CVLKRA_DOCUMENT_FAILED';

export const FETCH_CKYC_DOCUMENT_REQUEST = 'FETCH_CKYC_DOCUMENT_REQUEST';
export const FETCH_CKYC_DOCUMENT_SUCCESS = 'FETCH_CKYC_DOCUMENT_SUCCESS';
export const FETCH_CKYC_DOCUMENT_FAILED = 'FETCH_CKYC_DOCUMENT_FAILED';

export const SET_FUND_DATA_FOR_PAYMENT= 'SET_FUND_DATA_FOR_PAYMENT';
export const CLEAR_FUND_DATA_FOR_PAYMENT= 'CLEAR_FUND_DATA_FOR_PAYMENT';

export const FETCH_SIPS_LIST_SUCCESS= 'FETCH_SIPS_LIST_SUCCESS';
export const FETCH_SIPS_LIST_FAILED= 'FETCH_SIPS_LIST_FAILED';
export const FETCH_SIPS_LIST_REQUEST= 'FETCH_SIPS_LIST_REQUEST';

export const CANCEL_SIP_SUCCESS="CANCEL_SIP_SUCCESS";
export const CANCEL_SIP_REQUEST=  "CANCEL_SIP_REQUEST";
export const CANCEL_SIP_FAILED="CANCEL_SIP_FAILED";

export const SELECTED_REDEEM_PORTFOLIO='SELECTED_REDEEM_PORTFOLIO';

export const SUBMIT_CKYC_FORM_REQUEST = 'SUBMIT_CKYC_FORM_REQUEST';
export const SUBMIT_CKYC_FORM_SUCCESS = 'SUBMIT_CKYC_FORM_SUCCESS';
export const SUBMIT_CKYC_FORM_FAILED = 'SUBMIT_CKYC_FORM_FAILED';
