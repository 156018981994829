import axios from 'axios';
import { decrypt, encrypt } from "../utils/encrypt";
import { getAuthToken } from "../utils/authService";
import config from '../config';
export function bugAlert(data) {
   
  const encryptedData = encrypt(JSON.stringify(data));
  const token = getAuthToken();
  const defaultOptions = {
    withCredentials: true,
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
 axios
   .post(
     `${config.baseUrl}api/send_alert_email`,
     { encryptedData },
     { ...defaultOptions }
   )
   .then(res => {
    
   });
}
