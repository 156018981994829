import {
    GET_CKYC_FORM_DATA_REQUEST,
    GET_CKYC_FORM_DATA_SUCCESS,
    GET_CKYC_FORM_DATA_FAILED,
    POST_CKYC_FORM_DATA_REQUEST,
    POST_CKYC_FORM_DATA_SUCCESS,
    POST_CKYC_FORM_DATA_FAILED,
    SET_CKYC_PDF_URL,
} from "../Constants/actionTypes.constants";


export const initialStore = {
    loading:false,
    error:false,
    ckycformData:{
        "application_kyc_data": {
          applicantType:"New",//Update
          accountType:"Normal",//Normal Simplified(for low risk customers) Small,
          kyc_number:"",
          user_first_name:"",
          user_middle_name:"",
          user_last_name:"",
          swd_first_name:"",
          swd_middle_name:"",
          swd_last_name:"",
          mother_first_name:"",
          mother_middle_name:"",
          mother_last_name:"",
          "aadhaar_number": "", 
          "occupation_sector":"",
          "cor_add1": "", 
          "cor_add2": "", 
          "cor_add3": "", 
          "cor_add_city": "", 
          "cor_add_country": "", 
          "cor_add_pincode": "", 
          "cor_add_proof": "", 
          "cor_add_state": "", 
          "date_of_declaration": "", 
          "occupation_type":"",
          "dob": "", 
          "document_type": "", 
          "email": "", 
          "father_name": "", 
          "gender": "F", 
          "ipv_date": "", 
          "marital_status": "", 
          "mob_no": "", 
          "name": "", 
          "maidan_name": "", 
          "mother_name":"",
          "nationality": "", 
          "other_nationality": "", 
          "pan_no": "", 
          "per_add1": "", 
          "per_add2": "", 
          "per_add3": "", 
          "per_add_city": "", 
          "per_add_country": "", 
          "per_add_flag": "", 
          "per_add_pincode": "", 
          "per_add_proof": "", 
          "per_add_state": "", 
          "place_of_declaration": "", 
          "pos_code": "", 
          "residential_status": "", 
          "spouse_name": "", 
          "validity_of_cor_add_proof": "", 
          "validity_of_per_add_proof": "",
          institution_code :"",
          institution_name :"LOTUSDEW WEALTH AND INVESTMENT ADVISORS PVT LTD "
        }, 
        "b64_photo": "",
         "signature64":"",
         "stamp64":""
    },
    kycgeneratestatus:"",
    kycSubmitStatus:"",
    ckycPdfUrl:'',
    ckycGeneratePdf: '',
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case GET_CKYC_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                kycgeneratestatus:""
            };
        case GET_CKYC_FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                ckycformData: action.data,
                kycgeneratestatus:"SUCCESS"
            };
        case GET_CKYC_FORM_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error:true,
                kycgeneratestatus:"FAILED"
            };
        case POST_CKYC_FORM_DATA_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case POST_CKYC_FORM_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                ckycGeneratePdf: action.data,
            };
        case POST_CKYC_FORM_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error:true,
            };
        case SET_CKYC_PDF_URL:
            return {
                ...state,
                ckycPdfUrl:action.data
            };
        default:
            return state
    }
}

