import {
  GET_OMNIBUS_STATUS_REQUEST,
  GET_OMNIBUS_STATUS_SUCCESS,
  GET_OMNIBUS_STATUS_FAILED,
  SET_PURCHASE_ORDER,
  GET_PAYEZ_STATUS_REQUEST,
  GET_PAYEZ_STATUS_SUCCESS,
  GET_PAYEZ_STATUS_FAILED,
  SET_PAYEZ_STATUS_DETAILS,
  SET_BANK_ACCOUNT_MISMATCH
} from "../../Constants/mfActionTypes.constants";
import { get } from "lodash";

export const initialStore = {
  omninusStatus: {},
  loading: false,
  setPurchaseOrder: false,
  error: false,
  payezStatusDetails: [],
  payezOrderDetails: [],
  payezLoader: false,
  bankAccountMismatch: false,
};

export default (state = initialStore, action) => {
  switch (action.type) {
    case GET_OMNIBUS_STATUS_REQUEST:
      return {
        ...state,
        error: false,
        omninusStatus: {},
      };
    case GET_OMNIBUS_STATUS_SUCCESS:
      return {
        ...state,
        omninusStatus: action.data,
        error: false,
      };
    case GET_OMNIBUS_STATUS_FAILED:
      return {
        ...state,
        omninusStatus: {},
        error: true,
      };
    case SET_PURCHASE_ORDER:
      return {
        ...state,
        setPurchaseOrder: action.flag,
      };
    case GET_PAYEZ_STATUS_REQUEST:
      return {
        ...state,
        payezLoader: true,
        error: false,
      };
    case GET_PAYEZ_STATUS_SUCCESS:
      return {
        ...state,
        payezLoader: false,
        payezOrderDetails: action.data,
        error: false,
      };
    case GET_PAYEZ_STATUS_FAILED:
      return {
        ...state,
        payezLoader: false,
        error: true,
      };
    case SET_PAYEZ_STATUS_DETAILS:
      return {
        ...state,
        payezStatusDetails: action.data,
      };
    case SET_BANK_ACCOUNT_MISMATCH:
      return {
        ...state,
        bankAccountMismatch: action.flag,
      };
    default: // need this for default case
      return state;
  }
};
