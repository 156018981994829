import {
    CLEAR_FUND_DATA_FOR_PAYMENT,
    SET_FUND_DATA_FOR_PAYMENT
} from "../Constants/actionTypes.constants";


export const initialStore = {
    assetClass:'',
            selectedfund: '',
            amount: '',
            fundList: {},
            checked: true,
            selectedMandate: '',
            fundNotAvailable: false, 
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case SET_FUND_DATA_FOR_PAYMENT:
            return {
                ...state,
                ...action.data
            };
        case     CLEAR_FUND_DATA_FOR_PAYMENT   :
            return {
                ...initialStore
            };
        default:
            return state
    }
}

