import {
    GET_CONSENT_STATUS_REQUEST,
    GET_CONSENT_STATUS_SUCCESS,
    GET_CONSENT_STATUS_FAILED,
    GET_HOLDINGS_CONSENT_REQUEST,
    GET_HOLDINGS_CONSENT_SUCCESS,
    GET_HOLDINGS_CONSENT_FAILED,
} from '../../Constants/mfActionTypes.constants';
import { get } from 'lodash';
export const initialStore = {
  error: false,
  consentStatus: {},
  holdingConsentDetails: {},
  loading:false,
};
export default (state = initialStore, action) => {
  switch (action.type) {
        case GET_CONSENT_STATUS_SUCCESS:
            return {
                ...state,
                consentStatus: action.data,
                error: false,
            };
        case GET_CONSENT_STATUS_FAILED:
            return {
                ...state,
                error: true,
            };
        case GET_CONSENT_STATUS_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GET_HOLDINGS_CONSENT_REQUEST:
            return {
                ...state,
                error: false,
                loading:true,
            };
        case GET_HOLDINGS_CONSENT_SUCCESS:
            return {
                ...state,
                holdingConsentDetails: action.data,
                error: false,
                loading:false,
            };
        case GET_HOLDINGS_CONSENT_FAILED:
            return {
                ...state,
                error: true,
                loading:false
            };
        default:
          return state;
  }
};
