import {
    GET_BLOG_LIST_REQUEST,
    GET_BLOG_LIST_SUCCESS,
    GET_BLOG_LIST_FAILED,
    GET_BLOG_CONTENT_REQUEST, GET_BLOG_CONTENT_SUCCESS, GET_BLOG_CONTENT_FAILED,
} from "../Constants/actionTypes.constants";

export const initialStore = {
    blogList: [],
    blogContent: [],
    loading: false,
    contentLoader:false,
};



export default (state = initialStore, action) => {
    switch (action.type) {
        case GET_BLOG_LIST_REQUEST:
            return {
                ...state,
                loading: true,
                blogList: [],
            };
        case GET_BLOG_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                blogList: action.data.Blogs,
            };
        case GET_BLOG_LIST_FAILED:
            return {
                ...state,
                loading: false,
                blogList: [],
            };
        case GET_BLOG_CONTENT_REQUEST:
            return {
                ...state,
                contentLoader: true,
                blogContent: [],
            };
        case GET_BLOG_CONTENT_SUCCESS:
            return {
                ...state,
                contentLoader: false,
                blogContent: action.data,
            };
        case GET_BLOG_CONTENT_FAILED:
            return {
                ...state,
                contentLoader: false,
                blogContent: [],
            };
        default:
            return state
    }
}

