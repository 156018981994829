import {
    MANDATE_CREATE_REQUEST,
    MANDATE_CREATE_SUCCESS,
    MANDATE_CREATE_FAILED,
    EXECUTE_MANDATE_REQUEST,
    EXECUTE_MANDATE_SUCCESS,
    EXECUTE_MANDATE_FAILED,
    APPLY_COUPON_CODE_REQUEST,
    APPLY_COUPON_CODE_SUCCESS,
    APPLY_COUPON_CODE_FAILED,
    INVALID_COUPON,
    COUPON_EXPIRED,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_REQUEST,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_SUCCESS,
    GET_RECENT_DETAILS_VERIFICATION_HOOK_FAILED,
    GENERATE_INVOICE_PDF_REQUEST,
    GENERATE_INVOICE_PDF_SUCCESS,
    GENERATE_INVOICE_PDF_FAILED,
    SET_UMN
} from "../../Constants/mfActionTypes.constants";
import {get} from "lodash";

export const initialStore = {
    merchantTranId:'',
    mandateStatus: '',
    loading: false,
    error: false,
    errorMsg:'',
    couponCodeData: {},
    invoicePdfUrl: '',
    couponCode: '',
    tokenAmount: null,
    vpa: '',
    UMN: ''
};

export default (state = initialStore, action) => {
    switch (action.type) {
        case MANDATE_CREATE_REQUEST:
            return {
                ...state,
                merchantTranId: '',
                errorMsg: '',
                loading:true,
            };
        case MANDATE_CREATE_SUCCESS:
            return {
                ...state,
                merchantTranId: action.data.merchantTranId,
                errorMsg: '',
                loading:false,
            };
        case MANDATE_CREATE_FAILED:
            return {
                ...state,
                errorMsg: action.error,
                tokenAmount: action.amount,
                vpa: action.vpa,
                loading:false,
            };
        case EXECUTE_MANDATE_REQUEST:
            return {
                ...state,
                mandateStatus: 'requested',
                errorMsg: '',
            };
        case EXECUTE_MANDATE_SUCCESS:
            return {
                ...state,
                mandateStatus: 'success',
                errorMsg: '',
            };
        case EXECUTE_MANDATE_FAILED:
            return {
                ...state,
                mandateStatus: 'failed',
                errorMsg: action.error,
            };
        case APPLY_COUPON_CODE_REQUEST:
            return {
                ...state,
                couponCodeData: {},
                loading:true,
                error: false,
            };
        case APPLY_COUPON_CODE_SUCCESS:
            return {
                ...state,
                loading:false,
                couponCodeData: action.data,
                couponCode: action.couponCode,
                error: false,
            };
        case INVALID_COUPON:
            return {
                ...state,
                loading:false,
                couponCodeData: action.data,
                error: true,
            };
        case COUPON_EXPIRED:
            return {
                ...state,
                loading:false,
                couponCodeData: action.data,
                error: true,
            };       
        case APPLY_COUPON_CODE_FAILED:
            return {
                ...state,
                loading:false,
                couponCodeData: {},
                //errorMsg: action.error,
                error: true,
            };
        case GET_RECENT_DETAILS_VERIFICATION_HOOK_REQUEST:
            return {
                ...state,
            };
        case GET_RECENT_DETAILS_VERIFICATION_HOOK_SUCCESS:
            return {
                ...state,
                couponCodeData: get(action.data.userData, 'payment_details', {}),
            };
        case GET_RECENT_DETAILS_VERIFICATION_HOOK_FAILED:
            return {
                ...state,
            };
        case GENERATE_INVOICE_PDF_REQUEST:
            return {
                ...state,
                error: false,
            };
        case GENERATE_INVOICE_PDF_SUCCESS:
            return {
                ...state,
                invoicePdfUrl: action.data.signed_base64,
                error: false,
            };
        case GENERATE_INVOICE_PDF_FAILED:
            return {
                ...state,
                error: true,
            };
        case SET_UMN:
            return {
                ...state,
                UMN: action.UMN,
            };
        default:
            return state
    }
}

