import {
    FETCH_KYC_EMAIL_REQUEST,
    FETCH_KYC_EMAIL_SUCCESS,
    FETCH_KYC_EMAIL_FAILED,
    UNSUPPORTED_AUTO_EMAIL,
    SEND_OAUTH_EMAIL_REQUEST,
    SEND_OAUTH_EMAIL_SUCCESS,
    SEND_OAUTH_EMAIL_FAILED,
} from '../../Constants/mfActionTypes.constants';
import { get } from 'lodash';
export const initialStore = {
  error: false,
  kycUserEmailData :{},
  emailSupported: false,
  oauthUrl:'',
  oauthData:[],
  loading: false,
};
export default (state = initialStore, action) => {
  switch (action.type) {
        case FETCH_KYC_EMAIL_REQUEST:
            return {
                ...state,
                kycUserEmailData :{},
                error: false,
                loading: true,
            };
        case FETCH_KYC_EMAIL_SUCCESS:
            return {
                ...state,
                kycUserEmailData : action.data,
                error: false,
                emailSupported: true,
                loading: false,
            };
        case UNSUPPORTED_AUTO_EMAIL:
            return {
                ...state,
                kycUserEmailData : action.data,
                error: true,
                emailSupported: false,
                loading: false,
            };
        case FETCH_KYC_EMAIL_FAILED:
            return {
                ...state,
                kycUserEmailData : {},
                error: true,
                loading: false,
            };
        case SEND_OAUTH_EMAIL_REQUEST:
            return {
                ...state,
                oauthUrl:'',
                error: false,
                loading: true,
            };
        case SEND_OAUTH_EMAIL_SUCCESS:
            return {
                ...state,
                oauthData: action.data,
                oauthUrl: action.data.authorization_url,
                error: false,
                loading: false,
            };
        case SEND_OAUTH_EMAIL_FAILED:
            return {
                ...state,
                oauthUrl: '',
                error: true,
                loading: false,
            };
        default:
          return state;
  }
};
